import Utils from 'common/utils';

import { userDataStore } from 'dataStore';
import Constants from 'common/constants';

import PhoneValidator from 'common/PhoneValidator';
import { AbTestHelper } from 'helpers';
import { isAPIRepeatUser } from 'helpers/session/sessionHelpers';
import { IdStore } from 'dataStore/index';
import UserDataManager from 'providers/urlDataManager';

const _cleanPhoneValue = (phoneNo = '') =>
    !Utils.isNull(phoneNo) && phoneNo.includes('+') ? phoneNo.split('+').join(' ') : phoneNo;

export const getUserPhoneNumberSelector = () => {
    const oldUser = userDataStore.fetchUserDetailsFromStore();
    const urlPhone = Utils.filterInvalidUrlInput(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE) || '',
    );
    const userPhone = Utils.getObjVal(oldUser, ['phone_number']) || '';
    const maskedPhone = Utils.getObjVal(oldUser, ['meta', 'maskedPhoneNumber']);

    if (!Utils.isEmptyStr(urlPhone)) {
        return PhoneValidator.formatPhoneNumber(_cleanPhoneValue(urlPhone));
    }

    if (AbTestHelper.repeatUserAPIFlowV2() || !oldUser.phone_verification_status) {
        return '';
    }

    if (isAPIRepeatUser() && !Utils.isEmptyStr(maskedPhone)) {
        return PhoneValidator.formatPhoneNumber(maskedPhone, true);
    }

    return PhoneValidator.formatPhoneNumber(_cleanPhoneValue(userPhone));
};

export const getCityValueSelector = () => {
    return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY) || '';
};

export const getStateValueSelector = () => {
    return IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_REGION) || '';
};

export const getZipValueSelector = () => {
    const { zipCode } = UserDataManager.getPreferredUserDetailsFromUrlOverStore();

    return zipCode || '';
};
