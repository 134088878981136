import Utils from 'common/utils';
import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import { CommonHelper } from 'helpers';

/**
 *
 * @param {Object} prepingData data to be posted in request
 * @param {Object} storeKeyVal key value pair to be stored in session on success
 * @param {string} storeKeyVal.key
 * @param {string} storeKeyVal.value
 * @returns {undefined}
 */
const rexDirectPreping = async (prepingData, storeKeyVal) => {
    if (Utils.isEmptyObj(prepingData)) {
        console.error('Preping data not found');

        return;
    }

    let oldPrepingExists;

    if (!Utils.isEmptyObj(storeKeyVal)) {
        oldPrepingExists = IdStore.fetchIdForKey(storeKeyVal.key);
    }

    if (!Utils.isNull(oldPrepingExists)) {
        IdStore.removeIdForKey(storeKeyVal);
    }

    const { email } = prepingData;

    try {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'Rex_Direct_RDS');
        await fetch(Constants.API_PROXY_URL, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                url: `${Constants.PREPING_URLS.REXDIRECT}${email}`,
            }),
        })
            .then(str => str.text())
            .then(data => {
                if (data && data === 'Success') {
                    IdStore.storeIdForKey(storeKeyVal.key, storeKeyVal.value);
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_SUCCESS,
                        'Rex_Direct_RDS',
                    );
                } else {
                    IdStore.storeIdForKey(storeKeyVal.key, 'failed');
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_FAILURE,
                        'Rex_Direct_RDS',
                        {
                            error: `preping failed`,
                        },
                    );
                }
            });
    } catch (e) {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'Rex_Direct_RDS', {
            error: `error while sending preping: ${e.message}`,
        });
        console.log(e);
    }
};

export { rexDirectPreping };
