import Utils from 'common/utils';
import UserDataValidator from 'common/userDataValidator';
import { userDataStore } from 'dataStore';
import Constants from 'common/constants';
import flavors from 'flavors.macro';
import PhoneValidator from 'common/PhoneValidator';
import { AbTestHelper } from 'helpers';
import { isAPIRepeatUser } from 'helpers/session/sessionHelpers';

flavors();

const _cleanPhoneValue = (phoneNo = '') =>
    !Utils.isNull(phoneNo) && phoneNo.includes('+') ? phoneNo.split('+').join(' ') : phoneNo;

export const validateFormEmail = email => {
    return Utils.isEmptyStr(email) ? true : Utils.isNull(UserDataValidator.isValidEmail(email));
};

export const isAPIRepeatUserFlowV3 = checkStaleData =>
    isAPIRepeatUser(checkStaleData) && AbTestHelper.repeatUserAPIFlowV3();

export const getUserPhoneNumber = () => {
    const oldUser = userDataStore.fetchUserDetailsFromStore();
    const urlPhone = Utils.filterInvalidUrlInput(
        Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE) || '',
    );
    const userPhone = Utils.getObjVal(oldUser, ['phone_number']) || '';
    const maskedPhone = Utils.getObjVal(oldUser, ['meta', 'maskedPhoneNumber']);

    if (!Utils.isEmptyStr(urlPhone)) {
        return PhoneValidator.formatPhoneNumber(_cleanPhoneValue(urlPhone));
    }

    if (AbTestHelper.repeatUserAPIFlowV2() || !oldUser.phone_verification_status) {
        return '';
    }

    if (isAPIRepeatUser() && !Utils.isEmptyStr(maskedPhone)) {
        return PhoneValidator.formatPhoneNumber(maskedPhone, true);
    }

    return PhoneValidator.formatPhoneNumber(_cleanPhoneValue(userPhone));
};

export const overrideIframeAPIs = (iframeLoadEvent, _this) => {
    console.log('[Iframe][ADS: Overriding Iframe APIs', _this);
    _this._iframeWindowUrl = null;

    if (iframeLoadEvent && iframeLoadEvent.target && iframeLoadEvent.target.contentWindow) {
        iframeLoadEvent.target.contentWindow.open = (...args) => {
            console.log('[Iframe][ADS: open intercepted  ', args);
            _this._iframeWindowUrl = args[0];

            return window.open(...args);
        };
    }
};
