import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import { USER_STATUS } from 'common/userStatuses';
import Utils from 'common/utils';
import eventManager from 'eventManager/eventManager';
import userDataStore from 'dataStore/userDataStore';
import * as moment from 'moment';

const INTERMEDIATE_REG_TRANSITION_STATES = [
    USER_STATUS.NEW,
    USER_STATUS.NON_REGISTERED,
    USER_STATUS.SUBSCRIBED,
];

export const setUserStatus = (status, updateSessionUserStatus) => {
    switch (status) {
        case USER_STATUS.REG_CROSS_BROWSER_DEVICE:
        case USER_STATUS.REG_SAME_DEVICE:
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_SUBSCRIBED, 1);
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_REGISTERED, 1);
            break;
        case USER_STATUS.SUBSCRIBED:
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_SUBSCRIBED, 1);
            break;
        case USER_STATUS.NON_REGISTERED:
        case USER_STATUS.NEW:
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_SUBSCRIBED, 0);
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_IS_REGISTERED, 0);
            break;
        default:
            console.error('[UserReg] Invalid User Status passed', status);
    }

    if (updateSessionUserStatus) IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_STATUS, status);

    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_STATUS_CURRENT, status);
    IdStore.sync();
};

export const isAPIRepeatUserUpdateStale = () => {
    const oldUser = userDataStore.fetchUserDetailsFromStore();
    const createdAt = Utils.getObjVal(oldUser, ['created_at']);
    const diffMonth = moment().diff(moment(createdAt), 'month', true);

    return diffMonth > 6;
};

export const isAPIRepeatUser = checkStaleData => {
    const oldUser = userDataStore.fetchUserDetailsFromStore();
    const isRepeatUser = Utils.getObjVal(oldUser, ['meta', 'registrationStatus']) === 'Repeat';

    if (isRepeatUser && checkStaleData) return !isAPIRepeatUserUpdateStale();

    return isRepeatUser;
};

export const evaluateUserStatusOnAppInit = () => {
    const isRegistered = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_ID) ? true : false;
    const visitCount = parseInt(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.VISIT_COUNT));
    const user = userDataStore.fetchUserDetailsFromStore() || {};
    const userStatus = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS);
    const appInitEmail = Utils.getObjVal(user, ['email']);
    IdStore.storeIdForKey(Constants.ID_STORE_KEYS.INIT_EMAIL, appInitEmail);

    if (userStatus === USER_STATUS.REG_CROSS_BROWSER_DEVICE)
        setUserStatus(USER_STATUS.REG_CROSS_BROWSER_DEVICE, true);
    else if (isRegistered) {
        setUserStatus(USER_STATUS.REG_SAME_DEVICE, true);
    } else if (!Utils.isEmptyStr(appInitEmail)) {
        console.trace('[User][RepeatUser] Setting user status sub');
        setUserStatus(USER_STATUS.SUBSCRIBED, true);
    } else if (visitCount > 0) {
        setUserStatus(USER_STATUS.NON_REGISTERED, true);
    } else {
        setUserStatus(USER_STATUS.NEW, true);
    }
};

export const evaluateUserStatusOnUserRegCompletion = () => {
    const userStatusLocal = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS);

    if (INTERMEDIATE_REG_TRANSITION_STATES.includes(userStatusLocal))
        setUserStatus(USER_STATUS.REG_SAME_DEVICE);
};

export const evaluateUserStatusOnEmailSub = () => {
    const userStatusLocal = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS);

    if (INTERMEDIATE_REG_TRANSITION_STATES.includes(userStatusLocal))
        setUserStatus(USER_STATUS.SUBSCRIBED);
};

export const evaluateNewUserStatusTransition = isRepeatAPI => {
    if (isRepeatAPI) {
        setUserStatus(USER_STATUS.REG_CROSS_BROWSER_DEVICE, true);
    } else {
        setUserStatus(USER_STATUS.NEW, true);
        setUserStatus(USER_STATUS.SUBSCRIBED);
    }
};

export const trackUserStatus = () => {
    const user = userDataStore.fetchUserDetailsFromStore() || {};

    evaluateUserStatusOnAppInit();
    console.log(
        '[UserReg]: Evaluated User Status',
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS),
        user,
    );
};

export const triggerUserStatusTransitionEvent = () => {
    console.log('[UserReg]: Triggering Cross Browser Repeat');
    eventManager.sendEvent(
        Constants.EVENT.REPEAT_USER_STATUS_CHANGE,
        {},
        Constants.EVENT_TYPE.REGISTRATION,
    );
};

export const evaluateUserStatusOnAPIResponse = apiUserStatus => {
    const user = userDataStore.fetchUserDetailsFromStore() || {};

    const userStatusLocal = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS);
    const currentUserEmail = Utils.getObjVal(user, ['email']);
    const appInitEmail = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.INIT_EMAIL);

    if (
        apiUserStatus === 'Repeat' &&
        INTERMEDIATE_REG_TRANSITION_STATES.includes(userStatusLocal)
    ) {
        setUserStatus(USER_STATUS.REG_CROSS_BROWSER_DEVICE, true);
        triggerUserStatusTransitionEvent();
    } else if (!Utils.isEmptyStr(appInitEmail) && currentUserEmail !== appInitEmail) {
        evaluateNewUserStatusTransition(apiUserStatus === 'Repeat');
        triggerUserStatusTransitionEvent();
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT, 0);
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.USER_ID, null);
    } else {
        INTERMEDIATE_REG_TRANSITION_STATES.includes(userStatusLocal) &&
            setUserStatus(USER_STATUS.SUBSCRIBED);
    }
    console.log(
        '[USER][RepeatUser] User Status on API Resp : ',
        IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.USER_STATUS),
    );
};
