import Utils from './utils';
import Constants from 'common/constants';
import { getFlavor } from 'flavors.macro';

const flavor = getFlavor('layout-theme');

const EXTERNAL_PATHS = {
    ASSETS_PATH_STAGING: 'https://staging-assets.realdealsweeps.com',
    ASSETS_PATH: 'https://assets.realdealsweeps.com',
};

class RoutePrefixHandler {
    /**
     * Creates a route-template to be used in react-router.
     * In dev env, it'll just return the path as is.
     * @param {string} path
     */
    getRouteTemplateForPath(path) {
        return path;
    }

    /**
     * Creates the actual path(with the required prefixes) for a given path.
     * In dev environment, it will just return the path as is.
     * @param {string} path
     * @returns {string}
     */
    generateActualPathname(path) {
        if (Utils.isDevEnv()) {
            return path;
        }

        const strippedPath = this.stripPath(path);
        const pathsList = window.location.pathname.split('/');

        if (pathsList.length <= 0) {
            return path;
        }

        // Add the path as the last entry in the list
        pathsList[pathsList.length - 1] = strippedPath;

        return pathsList.join('/');
    }

    getErrorRoute() {
        return '/404';
    }

    getAssetsPath() {
        return Utils.isDevEnv() || Utils.isTestEnv()
            ? EXTERNAL_PATHS.ASSETS_PATH_STAGING
            : EXTERNAL_PATHS.ASSETS_PATH;
    }

    getImagePath(path) {
        const strippedPath = this.stripPath(path);

        return `${this.getAssetsPath()}/${strippedPath}`;
    }

    stripPath(path) {
        if (Utils.isNull(path)) {
            return '';
        }

        return path.replace(/^\/(.+)/, '$1');
    }

    getDefaultRedirectPathForLocation() {
        return flavor === 'sweepsPrizes'
            ? this.getRouteTemplateForPath(Constants.PAGE_ENDPOINTS.STAND_ALONE_PAGE)
            : this.getRouteTemplateForPath(Constants.PAGE_ENDPOINTS.LANDING);
    }
}

const handlerInstance = new RoutePrefixHandler();
export default handlerInstance;
