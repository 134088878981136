/* eslint-disable max-lines */
import Constants from 'common/constants';
import allowedJobTypes from 'flavors/myJobsCorner/common/jobTypes';
import flavors from 'flavors.macro';
import * as moment from 'moment';
import PhoneValidator from 'common/PhoneValidator';
import DateUtils from 'common/DateUtils';
import UserDataValidator from 'common/userDataValidator';

flavors();

const urlParams = {};
export default class Utils {
    static brandFromKeyword(param) {
        let keyword =
            param ||
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_TERM_G) ||
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.UTM_TERM);

        if (typeof keyword === 'undefined' || keyword === '' || keyword === null) return null;

        if (keyword.includes('-')) {
            keyword = keyword.split('-').join(' ');
        }

        if (keyword.includes('+')) {
            keyword = keyword.split('+').join(' ');
        }

        let matchValue = '';
        keyword = keyword.toLowerCase();

        for (const jt of allowedJobTypes) {
            if (keyword.includes(jt)) {
                matchValue = jt;
                break;
            }
        }

        return matchValue;
    }

    static getCurrentUrlParams() {
        let params;

        if ((params = urlParams[window.location.href])) {
            return params;
        } else {
            params = this.getAllUrlParams(window.location.href);
            urlParams[window.location.href] = params;

            return params;
        }
    }

    static getValForKeyFromCurrentUrl(key) {
        const paramsDict = this.getCurrentUrlParams();
        const val = paramsDict[key];

        if (this.isNull(val)) {
            return val;
        }

        return decodeURIComponent(val);
    }

    static getAllUrlParams(url) {
        let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
        const obj = {};

        if (queryString) {
            queryString = queryString.split('#')[0];
            const arr = queryString.split('&');

            for (let i = 0; i < arr.length; i++) {
                const a = arr[i].split('=');

                if (this.isNull(a) === true || a.length !== 2) {
                    continue;
                }
                let paramNum;
                const paramName = a[0].replace(/\[\d*\]/, v => {
                    paramNum = v.slice(1, -1);

                    return '';
                });
                const paramValue = this.isNull(a[1]) === true ? true : a[1];

                if (obj[paramName]) {
                    if (typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]];
                    }

                    if (typeof paramNum === 'undefined') {
                        obj[paramName].push(paramValue);
                    } else {
                        obj[paramName][paramNum] = paramValue;
                    }
                } else {
                    obj[paramName] = paramValue;
                }
            }
        }

        return obj;
    }

    static setLuckyOrangeTags(values) {
        window._loq = window._loq || [];
        console.log('Tags:', JSON.stringify(values));

        if (Array.isArray(values) && values.length > 0) {
            values.forEach(val => val && window._loq.push(['tag', val]));
        } else if (typeof values === 'object') {
            for (const [key, val] of Object.entries(values)) {
                if (val) window._loq.push(['tag', `${key}:${val}`]);
            }
        } else if (values) window._loq.push(['tag', values]);
    }

    static parseDateFromUrl() {
        const uDob = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.DOB),
        );

        return Constants.VALID_DATE_FORMATS.reduce((acc, format) => {
            if (acc && acc.isValid && acc.isValid()) {
                return acc;
            } else if (moment(uDob, format, true).isValid()) {
                return moment(uDob, format, true);
            }

            return acc;
        }, '');
    }

    static encodeObjectToUrlArgs(data) {
        if (this.isNull(data)) {
            return '';
        }

        const ret = [];

        for (const key in data) {
            ret.push(`${key}=${data[key]}`);
        }

        return ret.join('&');
    }

    static encodeUrlArgs(data) {
        if (this.isNull(data)) {
            return '';
        }

        const ret = [];

        for (const key in data) {
            ret.push(`${key}=${encodeURIComponent(data[key])}`);
        }

        return ret.join('&');
    }

    static convertDecimalToPercentageString(val) {
        if (typeof val === 'undefined' || val === null) {
            val = 0;
        } else if (typeof val === 'string') {
            val = parseFloat(val);

            if (true === isNaN(val)) {
                val = 0;
            }
        }

        return `${Math.round(val * 100)}`;
    }

    static isNull(obj) {
        return typeof obj === 'undefined' || obj === null;
    }

    static isEmptyStr(obj) {
        return this.isNull(obj) || obj === '';
    }

    static isEmptyObj(obj) {
        return this.isNull(obj) || Object.entries(obj).length === 0;
    }

    static isEmptyArr(arr) {
        return !Array.isArray(arr) || arr.length === 0;
    }

    /**
     * @param {*} arrA Prior Array
     *  @param {*} arrB Defer Array
     * @description Merges two arrays based on priority order
     * Eg: x = [a, b, null, c], y = [1,2,3]
     *     mergePriorArray(x,y)  -> [a, b, 1, c, 2, 3]
     */
    static mergePriorArray(arrA = [], arrB = []) {
        // Safety to avoid infinite iteration
        const SAFE_ITERATION_COUNT = 1000;

        try {
            let index = 0;
            const _temp = [...arrA];

            for (const _item of arrB) {
                let _iterationCount = 0;

                // Get next index for merge
                while (_temp[index]) {
                    index++;
                    _iterationCount++;

                    if (_iterationCount > SAFE_ITERATION_COUNT)
                        throw new Error('Merge Exceeds SAFE_ITERATION_COUNT');
                }
                _temp[index] = _item;
            }

            return _temp;
        } catch (e) {
            console.error('Error merging arrays', e);
        }
    }

    static getTimestampSecs() {
        return Math.floor(Date.now() / 1000);
    }

    static noop() {
        return () => {};
    }

    static formatFloatStrToFixed(fStr, precision) {
        if (Utils.isNull(fStr) || Utils.isNull(precision)) {
            return null;
        }

        try {
            let fixedFStr = Math.abs(parseFloat(fStr)).toFixed(precision);

            // Remove the cases where .00 occurs
            if (false === Utils.isNull(fixedFStr.match(/^-?\d+\.00$/))) {
                fixedFStr = fixedFStr.replace(/\.00$/, '');
            }

            return fixedFStr;
        } catch (e) {
            return null;
        }
    }

    static joinUrlPaths(pathsList) {
        const defaultVal = '';

        if (this.isNull(pathsList) || pathsList.length === 0) {
            return defaultVal;
        }

        const updatedPaths = pathsList.map(entry => {
            return `${entry}`.replace(/^\/+|\/+$/g, '');
        });

        return updatedPaths.join('/');
    }

    // Faithfully copied - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
    static getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    // Copied from here - https://stackoverflow.com/a/2117523/1518924
    static generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;

            return v.toString(16);
        });
    }

    /**
     * Dispatch a custom event on window.
     * @param {string} eventName
     * @param {object} data
     */
    static dispatchEvent(eventName, data) {
        if (Utils.isNull(data)) {
            data = {};
        }
        const completeEvent = new CustomEvent(eventName, { detail: data });
        window.dispatchEvent(completeEvent);
    }

    /**
     * Escape regex related symbols from string.
     * NOTE: Picked up from here - https://stackoverflow.com/a/6969486/1518924
     * Can't believe JS does not have an in-built method for this. Scratch that, I can really believe that :p
     * @param {string}
     * @returns {string}
     */
    static escapeRegExp(str) {
        if (typeof str !== 'string') {
            return str;
        }

        return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
    }

    /**
     * Returns true if using development environment.
     * @returns {bool}
     */
    static isDevEnv() {
        return process.env.NODE_ENV === 'development';
    }

    // / Faithfully picked up from - https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa

    /**
     * Convert ascii string into base64
     * @param {*} str
     */
    static encodeStrToBase64(str) {
        return window.btoa(unescape(encodeURIComponent(str)));
    }

    /**
     * Convert base64 into ascii string
     * @param {*} str
     */
    static decodeFromBase64(str) {
        return decodeURIComponent(escape(window.atob(str)));
    }

    /**
     * Checks if device is mobile
     */
    static checkMobileDevice() {
        let isMobile = false;

        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                window.navigator.userAgent,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                window.navigator.userAgent.substr(0, 4),
            )
        ) {
            isMobile = true;
        }

        return isMobile;
    }

    /**
     * Checks if str is equivalent to valid number
     */
    static isValidNumber(str) {
        return !isNaN(Number(str));
    }

    /*
     * static isValidDOB(input) {
     *     if (!input.length) return false
     */

    /*
     *     let dob = input.split('-')
     *     if (dob && dob.length !== 3) return false
     */

    /*
     *     return dob.every((number) => !isNaN(number))
     * }
     */

    static hasNonEmptyPIIFieldsFromUrl = () => {
        // let zipCode = Utils.filterInvalidUrlInput(Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ZIP))
        const firstName = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FNAME),
        );
        const lastName = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.LNAME),
        );
        const gender = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.GENDER),
        );
        const dob = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.DOB),
        );
        const address = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ADDRESS),
        );
        const email = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EMAIL),
        );
        const phone = PhoneValidator.formatPhoneNumber(
            Utils.filterInvalidUrlInput(Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE)),
        );
        const validatedDOB = moment(dob).isValid() && !DateUtils.isAboveTeenage(dob) ? true : false;

        if (
            firstName &&
            lastName &&
            gender &&
            dob &&
            email &&
            phone &&
            validatedDOB &&
            !UserDataValidator.isValidEmail(email) &&
            !PhoneValidator.isValidPhoneNumber(phone) &&
            address
        )
            return true;

        return false;
    };

    static parseAndStoreDetails = details => {
        const decodeStr = Utils.decodeFromBase64(details);

        try {
            if (Utils.isEmptyStr(decodeStr)) return;
            const det = JSON.parse(decodeStr);

            return det;
        } catch (e) {
            console.log('Parsing error', e);

            return false;
        }
    };

    static hasEmptyNameFields = () => {
        const firstName = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FNAME),
        );
        const lastName = Utils.filterInvalidUrlInput(
            Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.LNAME),
        );

        if (firstName && lastName) return true;

        return false;
    };

    static hashCode(stringVal) {
        let hash = 0;

        if (stringVal.length === 0) {
            return hash;
        }

        for (let i = 0; i < stringVal.length; i++) {
            const char = stringVal.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32bit integer
        }

        return hash;
    }

    static isTestEnv() {
        return (
            Utils.isDevEnv() ||
            window.location.host.startsWith('localhost') ||
            window.location.host.includes('test') ||
            window.location.host.includes('staging') ||
            window.location.host.includes('dev') ||
            window.location.host.includes('ppe')
        );
    }

    static isLocalEnv() {
        return window.location.host.startsWith('localhost');
    }

    static addScript(attribute, text, callback) {
        if (window.isLh) return;
        const s = document.createElement('script');

        for (const attr in attribute) {
            s.setAttribute(attr, attribute[attr] ? attribute[attr] : null);
        }
        s.innerHTML = text;
        s.onload = callback;
        document.body.appendChild(s);
    }

    static addScriptToFooter(attribute, text, callback) {
        if (window.isLh) return;
        const s = document.createElement('script');

        for (const attr in attribute) {
            s.setAttribute(attr, attribute[attr] ? attribute[attr] : null);
        }
        s.innerHTML = text;
        s.onload = callback;
        const footer = document.getElementsByTagName('footer')[0];
        footer && footer.appendChild(s);
    }

    static getCapitalizeStr(str) {
        return str
            ? str
                  .split(' ')
                  .map(word => {
                      return word ? word[0].toUpperCase() + word.slice(1) : '';
                  })
                  .join(' ')
            : '';
    }

    static getDeviceInfo() {
        const supportWebp = Utils.canUseWebP() ? 'webp' : 'noWebp';
        const deviceWidth = window.innerWidth < 600 ? 'sm' : 'lg';
        const deviceType = this.checkMobileDevice() ? 'mb' : 'dsk';

        return [deviceWidth, supportWebp, deviceType];
    }

    static firePixel(px) {
        const img = new Image();
        img.src = px;
    }

    static fireWithProxy(url) {
        const payload = {
            url: url,
            options: {
                method: 'GET',
                headers: {},
            },
        };
        fetch('https://api.cnxdserv.com/a/api/v2/proxy', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    static getJobType(param) {
        let jobType = param
            ? param.toLowerCase()
            : Utils.getValForKeyFromCurrentUrl('jt')
            ? Utils.getValForKeyFromCurrentUrl('jt').toLowerCase()
            : '';

        if (jobType.includes('-')) {
            jobType = jobType.split('-').join(' ');
        }

        if (jobType.includes('+')) {
            jobType = jobType.split('+').join(' ');
        }

        let isJobTypeAllowed = Utils.isJobTypeAllowed(jobType);
        const brand = Utils.brandFromKeyword(param);

        if (brand && !isJobTypeAllowed) {
            jobType = brand;
            isJobTypeAllowed = true;
        }

        return {
            jobType,
            isJobTypeAllowed,
        };
    }

    static isJobTypeAllowed(jobType) {
        if (this.isNull(jobType) || this.isEmptyStr(jobType)) {
            return false;
        }

        if (jobType.includes('-')) {
            jobType = jobType.split('-').join(' ');
        }

        if (jobType.includes('+')) {
            jobType = jobType.split('+').join(' ');
        }

        return allowedJobTypes.includes(jobType);
    }

    static canUseWebP() {
        const elem = document.createElement('canvas');

        if (elem.getContext && elem.getContext('2d')) {
            return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }

        return false;
    }

    static browserType() {
        const userAgent = window.navigator.userAgent.toLowerCase();

        if (userAgent.indexOf('samsungbrowser') !== -1) {
            return 'samsungbrowser';
        }

        if (userAgent.indexOf('chrome') !== -1) {
            return 'chrome';
        }

        if (userAgent.indexOf('safari') !== -1) {
            return 'safari';
        }

        if (userAgent.indexOf('firefox') !== -1) {
            return 'firefox';
        }
    }

    static isLiveSurvey() {
        const sid = Utils.getValForKeyFromCurrentUrl('sid');

        if (['45', '47', '49'].includes(sid)) {
            return true;
        }

        return false;
    }

    static minLength(input, length) {
        return input && input.length >= length;
    }

    static filterInvalidUrlInput(input) {
        if (input && (input.startsWith('{') || input.endsWith('}'))) {
            return '';
        }

        return input;
    }

    static safeAtob = hourlyPay => {
        try {
            return window.atob(hourlyPay);
        } catch (err) {
            console.error('[Utils] Unable to parse atob', hourlyPay, err);

            return '';
        }
    };

    static filterAndFromParams(input) {
        if (!input) return '';

        input = input.trim();

        if (input && input.startsWith('&')) {
            return input.slice(1);
        }

        return input;
    }
    /*
     * static openTab(url) {
     *     // Create link in memory
     *     var a = document.createElement("a");
     *     a.target = '_blank';
     *     a.href = url;
     */

    /*
     *     // Dispatch fake click
     *     let event = new Event("click");
     *     a.dispatchEvent(event);
     * };
     */

    static allowedDynFeedSrcList(source) {
        if (!source) return false;

        return [].indexOf(source.toLowerCase()) !== -1;
    }

    /**
     * Clean key word display value
     * @param {*} kw Keyword term
     */
    static cleanKwDisplay(kw) {
        if (!kw) return kw;

        let result = kw;
        const lowerCased = kw.toLowerCase();

        // remove jobs word
        if (lowerCased.endsWith('jobs') || lowerCased.endsWith('job')) {
            // Trim string
            result = kw.substr(0, lowerCased.lastIndexOf('job')).trim();
        }

        // remove - and +
        if (result.includes('-')) {
            result = result.split('-').join(' ');
        }

        if (result.includes('+')) {
            result = result.split('+').join(' ');
        }

        return result;
    }

    static getIOSVersion() {
        if (navigator && /iP(hone|od|ad)/.test(navigator.platform)) {
            const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);

            return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
    }

    static setCookie(name, value, minutes) {
        let expires = '';

        if (minutes) {
            const date = new Date();
            date.setTime(date.getTime() + minutes * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        }
        document.cookie = `${name}=${value || ''}${expires}; path=/`;
    }

    static getCookie(name) {
        const nameWithEq = `${name}=`;
        const cookieArray = document.cookie.split(';');

        for (let cookieStr of cookieArray) {
            while (cookieStr.charAt(0) === ' ') {
                cookieStr = cookieStr.substr(1, cookieStr.length);
            }

            if (cookieStr.indexOf(nameWithEq) === 0) {
                return cookieStr.substring(nameWithEq.length, cookieStr.length);
            }
        }

        return null;
    }

    static getCookieNamesWithValue(value) {
        const valWithEq = `=${value}`;
        const cookieArray = document.cookie.split(';');
        const matchingCookies = [];

        for (let cookieStr of cookieArray) {
            while (cookieStr.charAt(0) === ' ') {
                cookieStr = cookieStr.substr(1, cookieStr.length);
            }
            const indexOfVal = cookieStr.indexOf(valWithEq);

            if (indexOfVal > -1) {
                matchingCookies.push(cookieStr.substring(0, indexOfVal));
            }
        }

        return matchingCookies;
    }

    static deleteCookie(name) {
        document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    static deleteCookiesWithValue(value) {
        const matchingCookies = Utils.getCookieNamesWithValue(value);

        for (const cookieName of matchingCookies) {
            Utils.deleteCookie(cookieName);
        }
    }

    static enableBack2Listings(source) {
        if (this.isNull(source) || this.isEmptyStr(source)) return true;

        const enableFeature = !['awks'].includes(source.toLowerCase());

        return enableFeature;
    }

    static matchAndReplaceParams(str, replaceParams, matchRegex) {
        if (typeof str === 'string') {
            return str.replace(matchRegex, matched => {
                return replaceParams[matched] || '';
            });
        }
    }

    static replaceMacros(object, replaceParams, matchRegex) {
        if (Utils.isNull(object)) {
            return null;
        }
        Object.keys(object).forEach(key => {
            if (typeof object[key] === 'object') {
                object[key] = Utils.replaceMacros(object[key], replaceParams, matchRegex);
            } else if (typeof object[key] === 'string') {
                object[key] = Utils.matchAndReplaceParams(object[key], replaceParams, matchRegex);
            }
        });

        return object;
    }

    static getRandomValueInclusive(min, max) {
        const minimum = Math.ceil(min);
        const maximum = Math.floor(max);

        return Math.floor(Math.random() * (maximum - minimum + 1) + minimum);
    }

    /**
     * Checks the string if undefined, null, not typeof string, empty or space(s)
     * @param {any} str string to be evaluated
     * @returns {boolean} the evaluated result
     */
    static isStringNullOrWhiteSpace(str) {
        return (
            str === undefined ||
            str === null ||
            typeof str !== 'string' ||
            str.match(/^ *$/) !== null
        );
    }

    static isPreviewMode() {
        return !!Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PREVIEW) && Utils.isTestEnv();
    }

    /**
     * @param {string} str
     * @returns { JSON, null } JSON value or null
     */
    static jsonSafeParse(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            console.error('Error parsing json', e);

            return null;
        }
    }

    /**
     * insertLeadScript embeds leadScript into page
     * @param {} none
     * @returns null
     */
    static insertLeadScript() {
        Utils.addScript(
            {
                type: 'text/javascript',
                id: 'LeadiDscript',
            },
            `
        (function() {
            var s = document.createElement('script');
            s.id = 'LeadiDscript_campaign';
            s.type = 'text/javascript';
            s.async = true;
            s.src = '//create.lidstatic.com/campaign/8d3577e0-ff0e-0756-9525-635b9fde8045.js?snippet_version=2';
            var LeadiDscript = document.getElementById('LeadiDscript');
            LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
            })();
        `,
        );
    }

    /**
     * insertTrustedFormScript - embeds trustedFormScript
     * @param {} none
     * @returns null
     */
    static insertTrustedFormScript() {
        Utils.addScript(
            {
                type: 'text/javascript',
                id: 'xxTrustedFormCertUrlScript',
            },
            `
        (function () {
            var field = 'xxTrustedFormCertUrl';
            var provideReferrer = false;
            var invertFieldSensitivity = false;
            var tf = document.createElement('script');
            tf.type = 'text/javascript'; tf.async = true;
            tf.src = 'https://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l=' + new Date().getTime() + Math.random() + '&invert_field_sensitivity=' + invertFieldSensitivity;
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s);
        }
        )();
        `,
        );
    }

    /**
     * deleteKeyValFromStringParams method to delete key from url
     * @param {string} key
     * @returns { string } queryParam
     */

    static deleteKeyValFromStringParams(key) {
        let param,
            paramsArray = [];
        const querySearchParam = window.location.search;

        if (querySearchParam !== '') {
            paramsArray = querySearchParam.split('&');

            for (let i = paramsArray.length - 1; i >= 0; i -= 1) {
                param = paramsArray[i].split('=')[0];

                if (param === key) {
                    paramsArray.splice(i, 1);
                }
            }
        }

        return paramsArray.length ? paramsArray.join('&') : '';
    }

    /**
     *
     * @param {*} object
     * @param {*} keys
     * @description Gets the value at path of object. If the resolved value is undefined, the defaultValue is returned in its place.
     * @returns
     */
    static getObjVal(object = {}, keys = []) {
        return keys.reduce((prev, curr) => prev && prev[curr], object);
    }

    static addLink(attribute, text, callback) {
        if (window.isLh) return;
        const s = document.createElement('link');

        for (const attr in attribute) {
            s.setAttribute(attr, attribute[attr] ? attribute[attr] : null);
        }
        s.innerHTML = text ? text : '';
        document.head.appendChild(s);

        if (callback) {
            callback();
        }
    }

    static camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

    static snakeCaseKeysShallow = obj => {
        const newObj = {};
        Object.keys(obj).forEach(key => (newObj[Utils.camelToSnakeCase(key)] = obj[key]));

        return newObj;
    };
    /*
     *  --- Array utils ---
     * Ported from : https://stackoverflow.com/a/12646864
     */

    static shuffleArray(array) {
        const shuffledArray = [...array];

        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }

        return shuffledArray;
    }

    static getMaxKeyValPrefix() {
        if (Utils.isTestEnv()) return 's';

        return 'p';
    }
}
