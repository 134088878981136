import Utils from './utils';
// eslint-disable-next-line import/default
import Constants from './constants';

export default class Network {
    static get(url, headers, noCors, allowCookiesInCors, timeout) {
        return Network.fetch(url, 'GET', headers, null, noCors, allowCookiesInCors, timeout);
    }

    static post(url, headers, body, noCors, allowCookiesInCors, timeout) {
        return Network.fetch(url, 'POST', headers, body, noCors, allowCookiesInCors, timeout);
    }

    static fetch(url, method, headers, body, noCors, allowCookiesInCors, timeout) {
        return this.fetchWithTimeout(
            url,
            method,
            headers,
            body,
            noCors,
            allowCookiesInCors,
            timeout,
        );
    }

    static fetchWithTimeout(url, method, headers, body, noCors, allowCookiesInCors, timeout) {
        // Add default method
        if (Utils.isEmptyStr(method)) {
            method = 'GET';
        }

        // Create fetch argument
        const fetchArgs = {
            method: method,
        };

        // Check for no-cors mode
        if (false === Utils.isNull(noCors) && noCors === true) {
            noCors = false;
            fetchArgs.mode = 'no-cors';
            console.log('using no-cors');
        }

        // Add headers if present
        if (false === Utils.isNull(headers)) {
            let apiHeaders = headers;

            if (false === apiHeaders instanceof Headers) {
                apiHeaders = new Headers(headers);
            }
            fetchArgs['headers'] = apiHeaders;
        }

        // Add body if present
        if (false === Utils.isNull(body)) {
            if (body instanceof URLSearchParams) {
                fetchArgs['body'] = body;
            } else if (typeof body !== 'string') {
                body = JSON.stringify(body);
                fetchArgs['body'] = body;
            }
        }

        // Add default timeout
        if (Utils.isNull(timeout)) {
            timeout = Constants.DEFAULT_NETWORK_TIMEOUT_MS;
        }

        // Allow setting for cookies
        if (false === Utils.isNull(allowCookiesInCors)) {
            if (allowCookiesInCors === true) {
                fetchArgs['credentials'] = 'include';
            }
        } else {
            // Use the default
            if (Constants.CONFIG.DEFAULT_ALLOW_COOKIES_IN_CORS) {
                fetchArgs['credentials'] = 'include';
            }
        }

        return new Promise(function (resolve, reject) {
            if (Utils.isEmptyStr(url)) {
                reject(new Error('Url cannot be empty!'));
            }

            const interval = setTimeout(function () {
                clearTimeout(interval);
                reject({ statusCode: 504, status: 'timeout - client' });
            }, timeout);

            fetch(url, fetchArgs)
                .then(response => {
                    clearTimeout(interval);
                    // NOTE: Don't explicitly handle response.ok here. Leave it to the caller
                    resolve(response);
                })
                .catch(error => {
                    clearTimeout(interval);
                    reject(error);
                });
        });
    }
}
