import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './commonWrapper.styles';
import cn from 'classnames';

/**
 * CommonWrapper
 * @param {*} props
 * - alignTop
 * - children
 * @returns CommonWrapper
 */
const CommonWrapper = props => {
    const classes = useStyles(props);

    return (
        <Grid container>
            <Grid
                className={cn(classes.container, {
                    [classes.fullScreenOverrides]: props.fullScreenOverrides,
                })}
                item
                lg={8}
                sm={10}
                xs={12}>
                {props.children}
            </Grid>
        </Grid>
    );
};

export default CommonWrapper;
