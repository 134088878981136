import Constants from 'common/constants';
import { LocalStoreManager } from 'dataStore';
import Utils from 'common/utils';
import { getFlavor } from 'flavors.macro';

class UserDataStore {
    /**
     * Fetches the default key for the user-data.
     * Keeping this as a method since it can potentially become a bit more complex. :fingers-crossed:
     * @returns {string}
     */
    flavor = getFlavor('layout-theme');

    getStoreKeyForUser() {
        return `${this.flavor}_${Constants.USER.STORE_USER_KEY}`;
    }

    /**
     * Fetches the default key for the registered-user
     * @returns {string}
     */
    getStoreKeyForRegisteredUser() {
        return `${this.flavor}_${Constants.USER.STORE_REGISTERED_USER_KEY}`;
    }

    /**
     * Fetches the default key for the registered-user
     * @returns {string}
     */
    getStoreKeyForRegisteredEmails() {
        return `${this.flavor}_${Constants.USER.EMAILS_SUBMITTED}`;
    }

    /**
     * Returns
     * @returns {null|Object}
     */
    fetchUserDetailsFromStore() {
        const userKey = this.getStoreKeyForUser();
        const userDataStr = LocalStoreManager.getItem(userKey);

        if (false === Utils.isEmptyStr(userDataStr)) {
            try {
                const userData = JSON.parse(userDataStr);

                if (false === Utils.isEmptyObj(userData)) {
                    return userData;
                }
            } catch (e) {
                console.log('ERR: JSON-Parsing user-data string');
            }
        }

        return null;
    }

    /**
     * Store the user-details into the store (whichever that may be)
     * @param {Object}
     */
    storeUserDataInCache(userDetails) {
        if (Utils.isEmptyObj(userDetails)) {
            console.log("ERR: Could not store user-data in local-store since it's empty!");

            return;
        }

        // Fetch data from key and amend it to the existing data
        const existingData = this.fetchUserDetailsFromStore();

        const userKey = this.getStoreKeyForUser();

        try {
            if (false === Utils.isNull(existingData)) {
                userDetails = Object.assign(existingData, userDetails);
            }

            const userDataStr = JSON.stringify(userDetails);
            LocalStoreManager.setItem(userKey, userDataStr);
        } catch (e) {
            console.log('ERR: Error when trying to store user-data');
        }
    }

    /**
     * Store user registration status
     * @param {Boolean}
     */
    updateRegistrationStatus(registrationStatus) {
        const registeredUserKey = this.getStoreKeyForRegisteredUser();
        const registrationStatusStr = JSON.stringify(registrationStatus);

        LocalStoreManager.setItem(registeredUserKey, registrationStatusStr);
    }

    /**
     * Fetch user registration status
     * Returns
     * @returns {Boolean}
     */
    fetchRegistrationStatus() {
        const registeredUserKey = this.getStoreKeyForRegisteredUser();
        const registrationStatusStr = LocalStoreManager.getItem(registeredUserKey);

        try {
            const registrationStatus = JSON.parse(registrationStatusStr);

            return registrationStatus;
        } catch (e) {
            console.log('ERR: JSON-Parsing user-data string');
        }
    }

    /**
     * Returns
     * @returns {null|Array}
     */
    fetchRegisteredEmails() {
        const emailKey = this.getStoreKeyForRegisteredEmails();
        const emailStr = LocalStoreManager.getItem(emailKey);

        if (emailStr) {
            try {
                const emails = JSON.parse(emailStr);

                return emails;
            } catch (e) {
                console.error('ERR: JSON-Parsing email string');
            }
        }

        return null;
    }

    /**
     * Store the emails into the store
     * @param {Array}
     */
    storeRegisteredEmailsInCache(email) {
        if (Utils.isEmptyStr(email)) {
            console.error("ERR: Could not store email in local-store since it's empty!");

            return;
        }

        // Fetch data from key and amend it to the existing data
        const existingEmails = this.fetchRegisteredEmails() || [];
        const emailKey = this.getStoreKeyForRegisteredEmails();
        let emails = [];

        try {
            if (existingEmails.includes(email)) {
                return;
            }
            emails = [...existingEmails, email];
            const emailStr = JSON.stringify(emails);
            LocalStoreManager.setItem(emailKey, emailStr);
        } catch (e) {
            console.error('ERR: Error when trying to store user-data');
        }
    }
}

const userDataStore = new UserDataStore();
export default userDataStore;
