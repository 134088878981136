import React from 'react';
import { useStyles } from './logo.styles';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * LogoLoader
 * @param {*} props
 * - text
 * - type: gold, silver, diamond
 * @returns Banner
 */
const LogoLoader = props => {
    let size = 70;

    if (props.size && props.size > 70) {
        size = props.size;
    }

    const classes = useStyles({ size: size })();

    return (
        <Grow in={true}>
            <div className={classes.root}>
                <div className={classes.container}>
                    <CircularProgress
                        className={classes.spinner}
                        disableShrink
                        size={size}
                        thickness={2.5}
                        variant="indeterminate"
                    />
                </div>
            </div>
        </Grow>
    );
};

export default LogoLoader;
