export const Labels = {
    BIRTH_FIELDS_PLACEHOLDER: {
        DATE: 'Day',
        MONTH: 'Month',
        YEAR: 'Year',
        DOB: 'Date of Birth',
    },
    LANDER_CTA: 'Enter Now',
    REG1_CTA: 'Continue',
    REG2_CTA: 'Continue',
    REG2_SUBMITTING: 'Submitting',
    REG1_FORM_PLACEHOLDER: {
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        ZIP_CODE: 'Zip code',
    },
    REG2_FORM_PLACEHOLDER: {
        STREET_ADDRESS: 'Street Address',
        PHONE_NUMBER: 'Phone No.',
        DATE_OF_BIRTH: 'Date of Birth',
        STATE: 'State',
        CITY: 'City',
        ZIP_CODE: 'Zip code',
    },
    EMAIL_PLACEHOLDER: 'Email',
    DETAILS_CONFIRMATION_TCPA_LABEL: 'I Agree',
    MARKETING_PARTNERS: 'Marketing Partners',
    SKIP_HERE: 'skip here',
    SKIP: 'Skip',
    CLAIM_OFFER: 'Claim Offer',
    UNSUBSCRIBE: 'Unsubscribe',
    GO_TO_HOME: 'Go to Home',
    SUBMIT: 'Submit',
    SPIN_NOW: 'Spin Now',
    SLOT_BUTTON_WIN: 'Claim Now',
    TCPA_SECONDARY:
        'I agree that only my information is accurate and consent to be called and texted as provided above',
    CONGRATULATIONS_FNAME: (firstName = '') => `Congratulations ${firstName}`,
    CHECK_EMAIL_ENTRY: 'Go Check Your Email To confirm your entry.',
    OFFER_NOT_AVAILABLE_HEAD: 'Thank you for Visting',
    OFFER_NOT_AVAILABLE_SUB_HEAD:
        'Unfortunately, the offer you are interested in is unavailable at the moment',
    GOOGLE_RE_CAPTCHA_DISCLOSURE_CLONE:
        'This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms and Conditions</a>.',
};
