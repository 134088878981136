/* global grecaptcha */
import UrlManager from 'common/urlManager';
import Network from 'common/network';
import Constants from 'common/constants';
import eventManager from 'eventManager/eventManager';
import Utils from 'common/utils';

const reCaptchaSession = async (token, action, fnCallback) => {
    const status = Utils.getCookie('GRC_STATUS');
    console.log('[Log]: Captcha Regen Session called');

    if (status) {
        return;
    }

    Utils.setCookie('GRC_STATUS', 'in-progress', 30 * 24 * 60);

    const data = {};
    data['token'] = token;
    data['action'] = action;
    data['event'] = eventManager.getEventData('reCaptcha', {}, Constants.EVENT_TYPE.LANDING);

    const url = UrlManager.getReCaptchaUrl();
    const headers = {
        'Content-Type': 'application/json',
    };

    await Network.post(url, headers, data)
        .then(response => {
            if (false === response.ok) {
                console.log('[Log]: Response false for Captcha session');

                throw new Error(`ReCaptcha response status invalid - ${response}`);
            }

            return response.json();
        })
        .then(response => {
            Utils.setCookie('GRC_STATUS', JSON.stringify(response.data), 30 * 24 * 60);
            const suspect = response.data && response.data.suspect;

            if (suspect) {
                Utils.setCookie('GRC_CHALLENGE', 'failed', 30 * 24 * 60);
                eventManager.sendEvent(
                    Constants.EVENT.RECAPTCHA_CHALLENGE_FAILURE,
                    {},
                    Constants.EVENT_TYPE.LANDING,
                );
                fnCallback();
            }
        })
        .catch(reCaptchaErr => {
            console.log('ERR: ReCaptcha: error - ', reCaptchaErr);
        });
};

const genRecaptcha = async fnCallback => {
    const token = Utils.getCookie('GRC_TOKEN');
    const action = Utils.getCookie('GRC_ACTION');
    console.log('[Log]: Captcha Regen called');

    if (Utils.getCookie('GRC_CHALLENGE') === 'failed') {
        fnCallback();
    } else {
        try {
            if (token && action) {
                await reCaptchaSession(token, action);
            } else {
                // generate token

                if (grecaptcha && grecaptcha.enterprise && grecaptcha.enterprise.execute) {
                    await grecaptcha.enterprise
                        .execute(Constants.RECAPTCHA.NO_CHALLENGE_KEY, {
                            action: window.location.pathname,
                        })
                        .then(async function (token) {
                            Utils.setCookie('GRC_TOKEN', token, 30 * 24 * 60);
                            Utils.setCookie('GRC_ACTION', window.location.pathname, 30 * 24 * 60);
                            await reCaptchaSession(token, window.location.pathname, fnCallback);
                        });
                }
            }
        } catch (e) {
            console.log('ERR: Uncaught error in captcha regen', e);
        }
    }
};

export { genRecaptcha };
