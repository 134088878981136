import Utils from 'common/utils';

export const Messages = {
    DEFAULT_ERROR_TEXT: 'Something went wrong...',
    COPYRIGHT_MESSAGE: year => `${year} SweepsPrizes&trade; All Rights Reserved.`,
    LANDER: {
        TCPA: {
            PART_1: `"By clicking the button, I agree to the <a href='/privacy-policy' target='_blank'>privacy policy</a> and to receive relevant emails from RealDealSweeps and our`,
            PART_2:
                ". I understand that I'll need to confirm my email address to receive my entry.",
        },
        OFFICIAL_RULES:
            'No Purchase Necessary <span>see <a href="/official-rules" target="_blank">Official Rules</a></span>',
    },
    REG1: {
        FORM_TITLE: 'Official Entry Form',
        FORM_SUBTITLE:
            'In order to claim your <span class="rds-theme-bold-text">$5,000</span> entry, please confirm your details',
    },
    REG2: {
        FORM_TITLE: 'Official Entry Form',
        FORM_TITLE_V2: firstName => `Welcome back, ${Utils.getCapitalizeStr(firstName)}!`,
        FORM_SUBTITLE: firstName =>
            `${firstName}, where should we send you the <span class="rds-theme-bold-text">$5,000</span> if you win?`,
        FORM_SUBTITLE_V2: 'Confirm your details and re-enter to win $5,000 today',
    },
    UNDER_AGE_DOB: 'Age cannot be less than 18',
    REQUIRED_SELECT: 'Please select a value',
    REGISTERING_WAIT: 'Please wait',
    REGISTERING: 'We are registering your details with us...',
    DETAILS_CONFIRMATION: {
        TITLE: 'Entry Form Confirmation',
    },
    DETAILS_CONFIRMATION_TCPA_TEXT:
        'I agree that only my information is accurate and consent to be called and texted as provided above',
    DETAILS_CONFIRMATION_TCPA_ERROR: 'Please accept Terms and Conditions',
    DETAILS_CONFIRMATION_OPT_IN_TEXT: {
        PART1:
            'I agree to be contacted by phone and/or receive daily SMS text messages (messages and data rates may apply) by RealDealSweeps™ & ',
        PART2:
            " at the phone (including my wireless) number provided, whether or not the number is on my federal or state do not call registry. I acknowledge that these calls or SMS messages may be generated using an autodialer and may also contain pre-recorded messages. To opt-out at any time reply STOP, for help, reply with HELP. I represent that I am 18+ years of age and that I have read and agreed to the <a href='/terms-and-conditions' target='_blank'>Terms of Use</a>, <a href='/privacy-policy' target='_blank'>Privacy Policy</a> and <a href='/california-privacy-notice' target='_blank'>CA Privacy Notice</a>. To receive this information without providing the consent ",
    },
    OFFER_PAGE_TITLE: 'Congratulations!',
    OFFER_PAGE_SUBTITLE: 'You qualify for these special offers',
    ENTRY_CONFIRMED: 'Your Entry Has Been Confirmed, Thank You For Playing!',
    CONFIRM_ADDITIONAL_OFFERS: 'Want to win more exciting prizes?',
    UNSUBSCRIBE_SUB_TITLE: 'Good luck! We hope to see you again!',
    UNSUBSCRIBE_EMAIL_MESSAGE:
        'Please enter your email ID to unsubscribe from email notifications.',
    UNSUBSCRIBED_MESSAGE_PART1: "We're sad to see you leave!",
    UNSUBSCRIBED_MESSAGE_PART2: 'Sorry for letting you go.',
    ERROR_MESSAGES: {
        REQUIRED: 'This field is required',
        INVALID_FIRST_NAME: 'Please enter a valid first name',
        INVALID_LAST_NAME: 'Please enter a valid last name',
        INVALID_ZIP_CODE: 'Please enter a valid zip code',
        INVALID_STREET_ADDRESS: 'Please enter a valid street address',
        INVALID_PHONE: 'Please enter a valid phone number',
        INVALID_EMAIL: 'Please enter a valid email address',
    },
    ALTERNATE_ENTRY_TITLE: 'Alternate Entry Form',
    ALTERNATE_ENTRY_SUCCESS: 'Thank you for filling out your details.',
    PROCESSING_ENTRY: 'Processing entry…',
    SPIN_NOW_INITIAL: count => `You have <b>${count} spins</b> to win upto <b>$5000</b>`,
    SPIN_NOW_INITIALV2: count => `You have <b>${count} spins</b> to win up to <b>$5000</b>`,
    SPIN_RETRY: count =>
        `Sorry, You missed you have <b>${count} more ${count > 1 ? 'spins' : 'spin'}</b>`,
    SPIN_SUCCESS: "<b>CONGRATULATIONS</b>!, You're eligible to win <b>$5000.00</b>!!",
};
