import { IdStore } from 'dataStore';
import Utils from 'common/utils';
import UrlManager from 'common/urlManager';
import Network from 'common/network';
import * as Constants from 'common/constants';
import AnalyticsManager from './analyticsManager';
import { EVENT_CONSTANTS } from './common';
import { getFlavor } from 'flavors.macro';
import NotificationManager from 'notificationManager/notificationManager';
import { StringHelper } from 'helpers';

/**
 * If a value in data is an object, then we are calling function recursively to map values inside it
 * @param {*} data
 * @param {*} eventObj
 */

const allowedKeys = [
    'ts',
    'ua',
    'os',
    'ip',
    'browser',
    'device_type',
    'os_version',
    'browser_version',
    'device',
    'evt_id',
    'svid',
    'spid',
    'lid',
    'uid',
    'did',
    'sid',
    'first_name',
    'last_name',
    'email',
    'zip',
    'phone_number',
    'gender',
    'dob',
    'city',
    'state',
    'country',
    'region',
    'cs_id',
    'qid',
    'segid',
    'seg_index',
    'ans_val',
    'gid',
    'advid',
    'advnm',
    'cpc',
    'cpa',
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_term',
    'utm_content',
    'referrer',
    'domain',
    'cmpid',
    'crid',
    'rvn',
    'rv_type',
    'ervn',
    'lname',
    'lpath_name',
    'event_type',
    'is_skipped',
    'flv_name',
    'address',
    'hnp_url',
    'error',
    'responsedata',
    'dup',
    'qno',
    'spotno',
    'optno',
    'qnm',
    'optvl',
    'cat',
    'uuid',
    'job_type',
    'cmpnm',
    'pipv4',
    'leadid',
    'optxt',
    'cr',
    'pl',
    'ag',
    'tid',
    'clid',
    'val',
    'abt',
    'abt1',
    'abt2',
    'abt3',
    'abt4',
    'abt5',
    'abt6',
    'abt7',
    'abt8',
    'abt9',
    'abt10',
    'abt11',
    'abt12',
    'abt13',
    'abt14',
    'abt15',
    'abt16',
    'abt17',
    'abt18',
    'abt19',
    'abt20',
    'abt21',
    'abt22',
    'abt23',
    'abt24',
    'abt25',
    'abt26',
    'abt27',
    'abt28',
    'abt29',
    'abt30',
    'abt31',
    'abt32',
    'abt33',
    'abt34',
    'abt35',
    'abt36',
    'abt37',
    'abt38',
    'abt39',
    'abt40',
    'abt41',
    'abt42',
    'abt43',
    'abt44',
    'abt45',
    'abt46',
    'abt47',
    'abt48',
    'abt49',
    'abt50',
    'evs',
    'tf_curl',
    'w',
    'h',
    'ps',
    'pss',
    'purl',
    'dpr',
    'adt',
    'adst',
    'adst2',
    'ptype',
    'opid',
    'etid',
    'ljt',
    'lkw',
    'giftcard_id',
    'userType',
    'ow_id',
    'spotNo',
    'deal_name',
    'deal_id',
    'category',
    'category_id',
    'pdomain',
    'domain',
    'isrdr',
    'visitCount',
    'registered_visit_count',
    'pvs',
    'email_optin_ts',
    'phone_no_optin_ts',
    'feed_advnm',
    'feed_cmpnm',
    'logos',
    'hp',
    'ext1',
    'ext2',
    'ext3',
    'ext4',
    'ext5',
    'svopt',
    'pa',
    'user_status',
    'user_status_current',
    'is_sub',
    'is_reg',
    'offerid',
    'max_deal_id',
    'first_visit',
    'last_visit',
    'job_redirect_url',
    'vertical',
];

const _assignKeysToAllEvents = eventObj => {
    eventObj[Constants.ID_STORE_KEYS.JOB_TYPE_VAL] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.JOB_TYPE_VAL,
    );

    if (!IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.IS_RDR)) {
        eventObj[Constants.ID_STORE_KEYS.IS_RDR] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.IS_RDR,
        );
    }

    if (
        !Utils.isNull(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.VISIT_COUNT)) &&
        !IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.VISIT_COUNT)
    ) {
        eventObj[Constants.ID_STORE_KEYS.VISIT_COUNT] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.VISIT_COUNT,
        );
    }

    if (
        !Utils.isNull(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT)) &&
        !IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT)
    ) {
        eventObj[Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT] = IdStore.fetchIdForKey(
            Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT,
        );
    }

    eventObj[Constants.NOTIFICATIONS.PUSH_ACCESS] = NotificationManager.getNotificationStatusCode();
    eventObj[Constants.ID_STORE_KEYS.FIRST_VISIT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.FIRST_VISIT,
    );
    eventObj[Constants.ID_STORE_KEYS.LAST_VISIT] = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.LAST_VISIT,
    );

    if (
        !eventObj.pvs &&
        !Utils.isNull(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PHONE_VERIFICATION))
    ) {
        eventObj['pvs'] = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PHONE_VERIFICATION);
    }

    if (
        !eventObj.evs &&
        !Utils.isNull(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.EMAIL_VERIFICATION))
    ) {
        eventObj['evs'] = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.EMAIL_VERIFICATION);
    }
    eventObj['vertical'] = 'sweeps';
};

const mapDataToEventObj = (data, eventObj) => {
    const keys = Object.keys(data);

    keys.forEach(key => {
        if (
            EVENT_CONSTANTS.ALLOWED_ID_VALS.indexOf(key) !== -1 ||
            EVENT_CONSTANTS.ALLOWED_TRANSIENT_VALS.indexOf(key) !== -1
        ) {
            if (key === `${getFlavor('layout-theme')}_${Constants.USER.STORE_USER_KEY}`) {
                const obj = data && data[key] && JSON.parse(data[key]);

                if (!Utils.isNull(obj) && typeof obj === 'object') {
                    mapDataToEventObj(obj, eventObj);

                    return;
                }
            }

            if (!eventObj[key] && !Utils.isNull(data[key])) {
                eventObj[key] = data[key];
            }
        }
    });

    _assignKeysToAllEvents(eventObj);

    return eventObj;
};

class EventMeta {
    constructor(eventName, eventData, eventType) {
        this.eventName = eventName;
        this.eventData = eventData;
        this.eventType = eventType;
        this.fireOnVisible = false;
    }

    setFireOnVisible(visiblity) {
        this.fireOnVisible = visiblity;
    }
}

class EventManager {
    constructor() {
        this.isSessionInitialized = false;
        this.sessionInitializedEventHandler = this.sessionInitializedEventHandler.bind(this);
        this.visiblityQueue = [];
    }

    initialize() {
        // Listen to session manager
        window.addEventListener(
            Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
            this.sessionInitializedEventHandler,
        );
    }

    sessionInitializedEventHandler() {
        try {
            window.removeEventListener(
                Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED,
                this.sessionInitializedEventHandler,
            );
        } catch (rmErr) {
            console.log('SESSION_EVENT: Error removing the event-listener - ', rmErr);
        }
        console.log('SESSION_EVENT: Session initialized!');

        this.isSessionInitialized = true;
    }

    /**
     * Store data in seesion storage
     * @param {object} data
     */
    storeTransientData(data) {
        if (Utils.isEmptyObj(data)) {
            console.error('Key value pair expected for storing data in web storage');

            return;
        }
        Object.keys(data).forEach(key => IdStore.storeIdForKey(key, data[key]));
    }

    /**
     * Send event to remote
     * @param {string} eventName
     * @param {object} eventData
     */
    async sendEvent(eventName, eventData, eventType) {
        if (window.isLh) return;
        const eventMeta = new EventMeta(eventName, eventData, eventType);
        this.sendEventMeta(eventMeta);
    }

    sendEventWhenVisible(eventName, eventData, eventType) {
        const eventMeta = new EventMeta(eventName, eventData, eventType);
        eventMeta.setFireOnVisible(true);
        this.sendEventMeta(eventMeta);
    }

    createAndGetEventUrl(eventName, eventData, eventType) {
        const eventMeta = new EventMeta(eventName, eventData, eventType);
        const eventObj = this.createEventObj(eventMeta);

        return this.getEventUrl(eventObj);
    }

    getEventData(eventName, eventData, eventType) {
        const eventMeta = new EventMeta(eventName, eventData, eventType);
        const eventObj = this.createEventObj(eventMeta);

        // push only allowed keys in the event
        const keys = Object.keys(eventObj).filter(k => allowedKeys.indexOf(k) !== -1);
        const filteredObject = {};
        keys.forEach(k => {
            if (!StringHelper.isNull(eventObj[k])) {
                filteredObject[k] = encodeURIComponent(eventObj[k]);
            }
        });

        return filteredObject;
    }

    sendEventMeta(eventMeta) {
        if (Utils.isNull(eventMeta)) {
            console.log(`EVENT_DATA: Not sending since event-meta is empty!`);

            return;
        }

        const eventObj = this.createEventObj(eventMeta);

        if (Utils.isNull(eventObj)) {
            console.log(
                `EVENT_DATA: Not sending ${eventMeta.eventName} since event-object is empty!`,
            );

            return;
        }

        console.log(`EVENT_DATA: ${eventMeta.eventName} -> ${JSON.stringify(eventObj)}`);

        if (eventMeta.fireOnVisible) {
            this.visiblityQueue.push(eventObj);

            return;
        }
        this.sendEventRequestWithObj(eventObj)
            .then(() => {
                console.log(`EVENT_DATA: Sent event - ${eventMeta.eventName}`);
            })
            .catch(err => {
                console.log(`EVENT_DATA: Err sending event - ${eventMeta.eventName} - ${err}`);
            });

        AnalyticsManager.sendEventToAnalytics(eventObj);
    }

    addFixedEvtParams(eventObj) {
        eventObj[Constants.ID_STORE_KEYS.DEVICE_WIDTH] = window.screen.width;
        eventObj[Constants.ID_STORE_KEYS.DEVICE_HEIGHT] = window.screen.height;
        eventObj[Constants.ID_STORE_KEYS.DEVICE_PIXEL_RATIO] = window.devicePixelRatio;
        eventObj[Constants.ID_STORE_KEYS.PAGE_URL] = window.location.href;

        return eventObj;
    }

    createEventObj(eventMeta) {
        const { eventName, eventData, eventType } = eventMeta;

        if (Utils.isEmptyStr(eventName)) {
            console.error('EVENT_DATA: ERR: event-name cannot be empty');

            return null;
        }

        // TODO: Pre-process the response data
        let eventObj = {};

        if (false === Utils.isNull(eventType)) {
            eventObj['event_type'] = eventType;
        }

        this.addFixedEvtParams(eventObj);
        let status = 0;

        try {
            if (Notification) {
                // granted=1, denied=2, default=0, not supported=4
                switch (Notification.permission) {
                    case 'granted':
                        status = 1;
                        break;
                    case 'denied':
                        status = 2;
                        break;
                    default:
                        status = 0;
                        break;
                }
            }
        } catch {
            status = 4;
        }

        eventObj[Constants.NOTIFICATIONS.PUSH_ACCESS] = status;
        const tfCertUrl =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_TRUSTED_FORM_CERT_URL) ||
            (document.getElementById('xxTrustedFormToken_0') &&
                document.getElementById('xxTrustedFormToken_0').value);
        const leadId =
            IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.REG2_LEADID) ||
            (document.getElementById('leadid_token') &&
                document.getElementById('leadid_token').value);

        eventObj[Constants.ID_STORE_KEYS.DEVICE_WIDTH] = window.screen.width;
        eventObj[Constants.ID_STORE_KEYS.DEVICE_HEIGHT] = window.screen.height;
        eventObj[Constants.ID_STORE_KEYS.DEVICE_PIXEL_RATIO] = window.devicePixelRatio;
        eventObj[Constants.ID_STORE_KEYS.PAGE_URL] = window.location.href;

        if (false === Utils.isNull(tfCertUrl)) {
            eventObj[Constants.EVENT.TF_CURL] = tfCertUrl;
        }

        if (false === Utils.isNull(leadId)) {
            eventObj[Constants.EVENT.LEAD_ID] = leadId;
        }

        // Add all the store entries & event entries
        const idObj = IdStore.fetchAllIds();

        if (false === Utils.isNull(idObj)) {
            // Only add entries from the list into the event object
            const mappedData = mapDataToEventObj(idObj, eventObj);
            eventObj = {
                ...eventObj,
                ...mappedData,
            };
        }

        if (false === Utils.isEmptyObj(eventData)) {
            eventObj = Object.assign(eventObj, eventData);
        }

        if (Utils.isEmptyObj(eventObj)) {
            console.log(
                `ERR: Event object does not contain session-id - ${eventName} =>`,
                eventObj,
            );

            return null;
        }

        eventObj[EVENT_CONSTANTS.EVENT_ID_KEY] = eventName;

        return eventObj;
    }

    sendEventRequestWithObj(eventObj) {
        const url = this.getEventUrl(eventObj);

        // Make request
        return Network.get(url, null, true);
    }

    getEventUrl(eventObj) {
        // push only allowed keys in the event
        const keys = Object.keys(eventObj).filter(k => allowedKeys.indexOf(k) !== -1);
        const filteredObject = {};
        keys.forEach(k => (filteredObject[k] = encodeURIComponent(eventObj[k])));
        // Build the url
        const urlArgs = Utils.encodeObjectToUrlArgs(filteredObject);
        const url = `${UrlManager.getEventsUrl()}?${urlArgs}`;
        console.log(`EVENT_DATA: URL - ${url}`);

        return url;
    }

    storeEventMeta(eventMeta) {
        if (Utils.isNull(eventMeta)) {
            console.log('EVENT_DATA: Cannot store into event-meta since data is null!');

            return;
        }
        console.log(
            `EVENT_DATA: Storing ${eventMeta.eventName} into premature-events-list since session is uninitialized`,
        );
        this.prematureEventsList.push(eventMeta);

        // Drop old events after reaching a certain point
        if (this.prematureEventsList.length >= EVENT_CONSTANTS.EVENT_STORE_MAX_LIMIT) {
            const numEntries =
                this.prematureEventsList.length - EVENT_CONSTANTS.EVENT_STORE_MAX_LIMIT;

            if (numEntries > 0) {
                console.log(`EVENT_DATA: Dropping ${numEntries} from premature-events-list`);

                for (let i = 0; i < numEntries; i++) {
                    this.prematureEventsList.shift();
                }
            }
        }
    }

    fireOnVisible() {
        while (true) {
            const eventObj = this.visiblityQueue.shift();

            if (!eventObj) break;
            this.sendEventRequestWithObj(eventObj)
                .then(() => {
                    console.log(`VISIBILITY EVENT_DATA: Sent event - ${eventObj.evt_id}`);
                })
                .catch(err => {
                    console.log(
                        `VISIBILITY EVENT_DATA: Err sending event - ${eventObj.evt_id} - ${err}`,
                    );
                });
            AnalyticsManager.sendEventToAnalytics(eventObj);
        }
    }

    triggerGoogleOptimize() {
        AnalyticsManager.triggerGoogleOptimize();
    }
}

const eventManager = new EventManager();
export default eventManager;
