export const GCS_DEFAULTS = {
    GCS_REG1_SUB_HEADING: () => 'Confirm your email to proceed',
    GCS_PHONE_TITLE: () => 'Your gift card is ready!',
    GCS_INTERMEDIATE_TITLE: (amount, brand) =>
        `{ \t"TITLE": "Awesome!", \t"DESC": "Take a quick survey to claim your ${amount} ${brand} Gift Card" }`,
    GCS_SURVEYPATH_TITLE: (amount, brand) =>
        `Complete these steps to claim your $${amount} ${brand} Gift Card`,
    GCS_OFFERWALL_HEADER: () =>
        `You must complete  1 Gold, 1 Platinum, and 2 Diamond offers to continue!`,
    GCS_OFFERWALL_SUBHEADER: (type, quantity) =>
        `Complete <b>${quantity} ${
            type && type[0].toUpperCase() + type.slice(1).toLowerCase()
        }</b>  ${quantity > 1 ? 'offers' : 'offer'}`,
    GCS_EACHSCREEN_FOOTER: () =>
        'To receive the promotional gift card you must: 1) be a U.S. resident at least 18 years of age or older, 2) register with valid and truthful information and complete our survey, 3) <a href="https://rewardsurveyusa.com/terms-and-conditions#terms-and-conditions" target="_blank">complete 1 Gold offer, 1 Platinum offer, and 2 Diamond offers within 30 days of your sign-up date</a>, 4) adhere to our 30 day cancellation policy requirements, and 5) follow the Program Requirement listed in our <a href="https://rewardsurveyusa.com/terms-and-conditions" target="_blank">Terms and Conditions</a>. Available sponsor offers will vary. Offers may require a purchase, see the provided instructions on each offer for details on how to receive credit. These offers have been selected for you based on the answers that you’ve provided in the survey questions.Currently a $3 ( subject to change) shipping cost applies if you choose to opt for a physical card. Only one (1) Reward is permitted per household per one (1) year (12 months) period. You should receive your reward 4-6 weeks after mailing your completed reward redemption voucher. Please refer to our <a href="https://rewardsurveyusa.com/terms-and-conditions" target="_blank">Terms and Conditions</a> and <a href="https://rewardsurveyusa.com/privacy-policy" target="_blank">Privacy Policy</a> for a complete list of the Program Requirements.',
    GCS_LISTINGPAGE_TEXT: () => ({
        DID_COMPLETE_ALL_OFFERS: ' ',
        WHATS_NEXT: 'What’s next?',
        BE_ADVISED_TEXT:
            'Please be advised, it may take one to two weeks before we receive confirmation from our sponsors that you have completed the requirements.',
        CONGRATS_BODY:
            'You can view your status <a href="/reward-status">here</a> to see how your qualification process is going. In the meantime, check out these similar promotions from our partners.',
        UN_CONFIRM_BODY:
            'Once you submit all the required offers, our customer support will verify it. You can view your eligibility <a href="/reward-status">here</a> to see how your qualification process is going.',
    }),
    GCS_TRENDING_PROGRESS_BAR_TEXT: () => 'Survey,Offers,Receive your Gift Card!',
    GCS_INTERMITTENT_GIFT_CARD_READY: () => 'Getting your gift card ready…',
    GCS_NEW_EMAIL_TITLE: (giftCards, amount) =>
        `Complete ${giftCards} offers to qualify to receive a <span>${amount}</span> gift card!*`,
    GCS_COMPLIANCE_SUB_HEADING: () =>
        `*Upon completion of the <a href="https://rewardsurveyusa.com/terms-and-conditions" target="_blank">Program Requirements</a>`,
    GCS_REG2_SUB_HEADING: () =>
        'Help us with some basic information so that we know where to send it.',
};
