import { FeatureValueHelper } from 'helpers/index';

export const allowPushInOptIn = () => {
    try {
        const pushInPlacementConfig = FeatureValueHelper.getPushOptInPlacementValue() || {};
        const path = (window.location.pathname || '').replace('/', '');
        const hash = (window.location.hash || '').replace('#', '');
        const allowedScreens = pushInPlacementConfig.screens || [];

        return allowedScreens.includes(`${path}${hash}`);
    } catch (e) {
        console.error(e);
    }
};

export function getTypeNotification() {
    const pushPlacementConfigObject = FeatureValueHelper.getPushOptInPlacementValue();

    if (pushPlacementConfigObject) {
        const type =
            pushPlacementConfigObject && pushPlacementConfigObject.type
                ? pushPlacementConfigObject.type
                : 'double';
        console.log('Notification: Type', type);

        return type;
    }

    console.log('Notification: Type', 'double');

    return 'double';
}

export function getNotificationVariant() {
    const pushPlacementConfigObject = FeatureValueHelper.getPushOptInPlacementValue();
    let variant = 'default';

    if (pushPlacementConfigObject) {
        variant =
            pushPlacementConfigObject && pushPlacementConfigObject.variant
                ? pushPlacementConfigObject.variant
                : 'default';

        return variant;
    }

    return variant;
}
