import { fetchSessionData } from 'datasource';
import Utils from 'common/utils';
import Constants from 'common/constants';
import { IdStore } from 'dataStore';
import EventManager from 'eventManager/eventManager';
import { ErrLogger } from 'errLogger';

export default class AppCommon {
    /**
     * This contains methods that need to be called after an app has been mounted;
     */
    static async fireOnAppMounted() {
        if (window.isLh) return;
        fetchSessionData()
            .then(sessionData => {
                console.log('SESSION_DATA: ', JSON.stringify(sessionData));
                // Store the session value
                AppCommon.storeSessionResp(sessionData);

                // Dispatch session fetched
                Utils.dispatchEvent(Constants.INTERNAL_EVENTS.ON_SESSION_FETCHED);
            })
            .catch(sessionErr => {
                console.log('ERR: Failed fetching session -', sessionErr);
                ErrLogger.log(sessionErr);

                try {
                    EventManager.sendEvent(
                        'session_failed',
                        { responsedata: `${sessionErr}` },
                        Constants.EVENT_TYPE.LANDING,
                    );
                } catch (e) {
                    console.log(e);
                }
            });
    }

    /**
     * This contains methods that need to be called when an app has been rendered;
     * This will be called every time the app renders
     */
    static fireOnAppRendered() {
        EventManager.initialize();
    }

    static storeSessionResp(resp) {
        if (Utils.isNull(resp)) {
            console.log('Could not store session-response since it is null');

            return;
        }

        const sessionId = resp.id;

        if (false === Utils.isEmptyStr(sessionId)) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.SESSION_ID, sessionId);
        }

        if (false === Utils.isNull(resp.deviceId) && false === Utils.isEmptyStr(resp.deviceId)) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.DEVICE_ID, resp.deviceId);
        }

        const geoObj = resp.geo;

        if (false === Utils.isEmptyObj(geoObj)) {
            /*
             * geo ->
             * city: "mumbai"
             * cityLatLong: "19.075984,72.877656"
             * country: "IN"
             * deviceId: "9bd5b5aa-87e7-4131-9949-4662b98d5784"
             * region: "mh"
             * userIP: "122.15.120.178"
             */

            // Extract the geo data
            const city = geoObj['city'];

            if (
                false === Utils.isEmptyStr(city) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_CITY))
            ) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_CITY, city);
            }

            const country = geoObj['country'];

            if (
                false === Utils.isEmptyStr(country) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY))
            ) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_COUNTRY, country);
            }

            const region = geoObj['region'];

            if (
                false === Utils.isEmptyStr(region) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_REGION))
            ) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_REGION, region);
            }
            const zipFrmUrl = Utils.filterInvalidUrlInput(
                Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ZIP),
            );
            const isValidZip =
                zipFrmUrl && Utils.isValidNumber(zipFrmUrl) && zipFrmUrl.length === 5;
            const zipCode = isValidZip ? zipFrmUrl : geoObj['zip'];

            if (
                false === Utils.isEmptyStr(zipCode) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE))
            ) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE, zipCode);
            }

            const userIP = geoObj['userIP'];

            if (
                false === Utils.isEmptyStr(userIP) &&
                Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_IP))
            ) {
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GEO_IP, userIP);
            }
        } else {
            try {
                EventManager.sendEvent(
                    'nozip',
                    { responsedata: JSON.stringify(resp) },
                    Constants.EVENT_TYPE.LANDING,
                );
            } catch (e) {
                console.log(e);
            }
        }
    }
}
