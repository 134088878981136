import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        height: '60px',
    },
    bgWhite: {
        backgroundColor: theme.palette.common.white,
    },
    bgBlack: {
        backgroundColor: '#11230C',
    },
    picture: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            maxHeight: 42,
            width: 'auto',
        },
    },
    pictureBgWhite: {
        backgroundColor: '#ffffff',
    },
    halloweenHeader: {
        backgroundColor: '#401EA5',
        backgroundImage:
            'url(https://assets.realdealsweeps.com/images/realDealSweeps/background/header-bat-mobile.png)',
        [theme.breakpoints.up('sm')]: {
            backgroundImage:
                'url(https://assets.realdealsweeps.com/images/realDealSweeps/background/header-bat-desktop.png)',
        },
        '@media (max-width: 400px)': {
            backgroundSize: 'cover',
        },
    },
    halloweenHeaderTransparent: {
        background: 'transparent',
    },
}));
