import UrlManager from 'common/urlManager';
import Network from 'common/network';
import CommonDataSource from './commonDataSource';
import Utils from 'common/utils';
import { IdStore } from 'dataStore/index';
import Constants from 'common/constants';

export default class OfferWallDataSource {
    /**
     * Fetch offer-path from remote endpoint.
     * It returns a promise for whenever it is complete
     */
    static fetchAdsDataForTargetingParams(targetingParams, options) {
        console.log('OFFER_WALL: Fetching session');

        return new Promise((resolve, reject) => {
            const url = UrlManager.getAdsUrl();
            let reqBody = {};

            if (false === Utils.isNull(options)) {
                Object.assign(reqBody, options);
            }

            if (
                false === Utils.isNull(targetingParams) &&
                targetingParams.extras.surveyId !== '46'
            ) {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: Constants.CREATIVE_CATEGORIES,
                    },
                };
            } else {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: ['linkout', 'offers', 'dataoffer'],
                    },
                };
            }
            console.log('ADS: Making ads-request with body - ', reqBody);

            const headers = {
                'Content-Type': 'application/json',
            };

            const resp = IdStore.fetchIdForKey(Utils.hashCode(JSON.stringify(reqBody)));

            if (resp) {
                console.log('ADS:  returning cached response');

                try {
                    resolve(CommonDataSource.processJSONResponse(JSON.parse(resp)));
                } catch (err) {
                    reject(err);
                }

                return;
            }

            Network.post(url, headers, reqBody)
                .then(response => {
                    if (false === response.ok) {
                        console.error(new Error(`response status invalid - ${response}`));
                    }

                    return response.json();
                })
                .then(responseJson => {
                    IdStore.storeIdForKey(
                        Utils.hashCode(JSON.stringify(reqBody)),
                        JSON.stringify(responseJson),
                    );

                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    console.log('OFFER_WALL: Got response data - ', processedResp);
                    resolve(processedResp);
                })
                .catch(sessionErr => {
                    console.log('ERR: OFFER_WALL: error - ', sessionErr);
                    reject(sessionErr);
                });
        });
    }

    /**
     * Fetches offer wall data from offer wall manager
     * @param {*} targetingParams
     * @param {*} options
     * @param {*} offerWallId
     */

    static fetchOfferWallDataForTargetingParams(targetingParams, options, offerWallId) {
        console.log('OFFER_WALL: Fetching session');

        return new Promise((resolve, reject) => {
            const url = UrlManager.getOfferWallUrl(offerWallId);
            let reqBody = {};

            if (false === Utils.isNull(options)) {
                Object.assign(reqBody, options);
            }

            if (
                false === Utils.isNull(targetingParams) &&
                targetingParams.extras.surveyId !== '46'
            ) {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: Constants.CREATIVE_CATEGORIES,
                    },
                };
            } else {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: ['linkout', 'offers', 'dataoffer'],
                    },
                };
            }
            console.log('ADS: Making ads-request with body - ', reqBody);

            const resp = IdStore.fetchIdForKey(Utils.hashCode(JSON.stringify(url)));

            if (resp) {
                console.log('ADS:  returning cached response');

                try {
                    resolve(CommonDataSource.processJSONResponse(JSON.parse(resp)));
                } catch (err) {
                    reject(err);
                }

                return;
            }

            const headers = {
                'Content-Type': 'application/json',
            };

            Network.post(url, headers, reqBody)
                .then(response => {
                    if (false === response.ok) {
                        console.error(new Error(`response status invalid - ${response}`));
                    }

                    return response.json();
                })
                .then(responseJson => {
                    IdStore.storeIdForKey(
                        Utils.hashCode(JSON.stringify(url)),
                        JSON.stringify(responseJson),
                    );

                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    console.log('OFFER_WALL: Got response data - ', processedResp);
                    resolve(processedResp);
                })
                .catch(sessionErr => {
                    console.log('ERR: OFFER_WALL: error - ', sessionErr);
                    reject(sessionErr);
                });
        });
    }

    /**
     * Fetches offer wall data from offer wall manager
     * @param {*} targetingParams
     * @param {*} options
     * @param {*} offerWallConfig
     */

    static fetchOfferWallDataForTargetingParamsV2(targetingParams, options, offerWallConfig) {
        console.log('OFFER_WALL: Fetching session data');

        return new Promise((resolve, reject) => {
            const url = UrlManager.getOfferWallUrlV2();
            let reqBody = {
                offerWalls: offerWallConfig,
            };

            if (false === Utils.isNull(options)) {
                Object.assign(reqBody, options);
            }

            if (
                false === Utils.isNull(targetingParams) &&
                targetingParams.extras.surveyId !== '46'
            ) {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: Constants.CREATIVE_CATEGORIES,
                    },
                };
            } else {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: ['linkout', 'offers', 'dataoffer'],
                    },
                };
            }
            console.log('ADS: Making ads-request with body - ', reqBody);

            const resp = IdStore.fetchIdForKey(Utils.hashCode(JSON.stringify(offerWallConfig)));

            if (resp) {
                console.log('ADS:  returning cached response');

                try {
                    resolve(CommonDataSource.processJSONResponse(JSON.parse(resp)));
                } catch (err) {
                    reject(err);
                }

                return;
            }

            const headers = {
                'Content-Type': 'application/json',
            };

            Network.post(url, headers, reqBody)
                .then(response => {
                    if (false === response.ok) {
                        console.error(new Error(`response status invalid - ${response}`));
                    }

                    return response.json();
                })
                .then(responseJson => {
                    IdStore.storeIdForKey(
                        Utils.hashCode(JSON.stringify(url)),
                        JSON.stringify(responseJson),
                    );

                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    console.log('OFFER_WALL: Got response data - ', processedResp);
                    resolve(processedResp);
                })
                .catch(sessionErr => {
                    console.log('ERR: OFFER_WALL: error - ', sessionErr);
                    reject(sessionErr);
                });
        });
    }

    static fetchAdsForAdUnit(targetingParams, adUnitId, options) {
        return new Promise((resolve, reject) => {
            const url = UrlManager.getAdUnitUrl();
            let reqBody = {};

            if (false === Utils.isNull(options)) {
                Object.assign(reqBody, options);
            }

            if (adUnitId) {
                reqBody.adUnitId = adUnitId;
            }

            if (
                false === Utils.isNull(targetingParams) &&
                targetingParams.extras.surveyId !== '46'
            ) {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: Constants.CREATIVE_CATEGORIES,
                    },
                };
            } else {
                reqBody = {
                    ...targetingParams,
                    ...reqBody,
                    ...{
                        categories: ['linkout', 'offers', 'dataoffer'],
                    },
                };
            }
            console.log('ADS: Making ads-request with body - ', reqBody);

            const headers = {
                'Content-Type': 'application/json',
            };

            Network.post(url, headers, reqBody)
                .then(response => {
                    if (false === response.ok) {
                        console.error(new Error(`response status invalid - ${response}`));
                    }

                    return response.json();
                })
                .then(responseJson => {
                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    console.log('Ad Unit: Got response data - ', processedResp);
                    resolve(processedResp);
                })
                .catch(sessionErr => {
                    console.log('ERR: Ad Unit: error - ', sessionErr);
                    reject(sessionErr);
                });
        });
    }

    static fetchSingleAdForAdUnit(path) {
        return new Promise((resolve, reject) => {
            const url = UrlManager.getSingleAdUrl(path);
            Network.get(url)
                .then(response => {
                    if (false === response.ok) {
                        console.error(new Error(`response status invalid - ${response}`));
                    }

                    return response.json();
                })
                .then(responseJson => {
                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    console.log('Ad Unit: Got response data - ', processedResp);
                    resolve(processedResp);
                })
                .catch(sessionErr => {
                    console.log('ERR: Ad Unit: error - ', sessionErr);
                    reject(sessionErr);
                });
        });
    }

    static fetchCreativeForSlot(path) {
        console.log('Getting another creative for current slot');

        return new Promise((resolve, reject) => {
            const url = UrlManager.getSingleAdUrl(path);

            const resp = IdStore.fetchIdForKey(Utils.hashCode(url));

            if (resp) {
                console.log('ADS:  returning cached response');

                try {
                    resolve(CommonDataSource.processJSONResponse(JSON.parse(resp)));
                } catch (err) {
                    reject(err);
                }

                return;
            }

            Network.get(url)
                .then(response => {
                    if (false === response.ok) {
                        console.error(new Error(`response status invalid - ${response}`));
                    }

                    return response.json();
                })
                .then(responseJson => {
                    IdStore.storeIdForKey(Utils.hashCode(url), JSON.stringify(responseJson));

                    return CommonDataSource.processJSONResponse(responseJson);
                })
                .then(processedResp => {
                    console.log('OFFER_PATH: Got response data - ', processedResp);
                    resolve(processedResp);
                })
                .catch(sessionErr => {
                    console.log('ERR: OFFER_PATH: error - ', sessionErr);
                    reject(sessionErr);
                });
        });
    }
}
