import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        padding: '20px 10px',
    },
    links: {
        width: '90%',
        margin: '0 auto',
        height: 'auto',
        textAlign: 'center',
    },
    link: {
        opacity: 0.5,
        color: `${theme.palette.common.white} !important`,
        textDecoration: 'none',
        padding: 10,
        fontSize: 10,
        display: 'inline-block',
        [theme.breakpoints.up('xs')]: {
            fontSize: 12,
        },
    },
    copyRight: {
        opacity: 0.5,
        color: theme.palette.common.white,
        textDecoration: 'none',
        padding: 10,
        fontSize: 10,
        [theme.breakpoints.up('xs')]: {
            fontSize: 12,
        },
    },
}));
