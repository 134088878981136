import * as moment from 'moment';
import Utils from 'common/utils';
import PhoneValidator from 'common/PhoneValidator';
import { userDataStore } from 'dataStore';
import Constants from 'common/constants';
import DateUtils from 'common/DateUtils';

export default class UserDataManager {
    static getUserDetailsFromUrl() {
        let email = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.EMAIL) || '';
        let firstName = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.FNAME) || '';
        let lastName = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.LNAME) || '';
        let gender = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.GENDER) || '';
        let dob = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.DOB) || '';
        let phone = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.PHONE) || '';
        let address = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ADDRESS);
        let zipCode = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.ZIP) || '';
        firstName = Utils.filterInvalidUrlInput(firstName);
        lastName = Utils.filterInvalidUrlInput(lastName);
        email = Utils.filterInvalidUrlInput(email);
        gender = Utils.filterInvalidUrlInput(gender);
        dob = Utils.filterInvalidUrlInput(dob);
        address = Utils.filterInvalidUrlInput(address);
        phone = Utils.filterInvalidUrlInput(phone);
        phone = PhoneValidator.formatPhoneNumber(phone);
        zipCode = Utils.filterInvalidUrlInput(zipCode);

        if (zipCode) {
            if (isNaN(zipCode)) {
                zipCode = '';
            } else {
                zipCode = zipCode.substring(0, 5);
            }
        }

        if (!Utils.isEmptyStr(gender)) {
            gender = gender.toLowerCase();
        }

        if (!['male', 'female'].includes(gender)) {
            gender = '';
        }

        if (dob) {
            const dobMoment = Utils.parseDateFromUrl();

            dob =
                dobMoment &&
                dobMoment.isValid &&
                moment(dobMoment).isValid() &&
                !DateUtils.isAboveTeenage(dobMoment)
                    ? dobMoment
                    : '';
        }

        const addr = {
            line1: address || '',
            line2: '',
            state: '',
            city: '',
        };

        return {
            email,
            firstName,
            lastName,
            gender,
            dob,
            phone,
            addr,
            zipCode,
        };
    }

    static getUserDetailsFromStore() {
        let oldUserDetails = userDataStore.fetchUserDetailsFromStore();

        if (!oldUserDetails) {
            const address = {
                line1: '',
                line2: '',
                state: '',
                city: '',
            };
            oldUserDetails = {
                firstName: '',
                lastName: '',
                email: '',
                gender: '',
                dob: '',
                phone: '',
                addr: address,
            };
        } else {
            oldUserDetails = {
                firstName: oldUserDetails.first_name || '',
                lastName: oldUserDetails.last_name || '',
                email: oldUserDetails.email || '',
                gender: oldUserDetails.gender || '',
                dob: oldUserDetails.dob ? moment(oldUserDetails.dob) : '',
                phone: oldUserDetails.phone_number || '',
                addr: {
                    line1:
                        oldUserDetails.address && oldUserDetails.address.line1
                            ? oldUserDetails.address.line1
                            : '',
                    line2:
                        oldUserDetails.address && oldUserDetails.address.line2
                            ? oldUserDetails.address.line2
                            : '',
                    state:
                        oldUserDetails.address && oldUserDetails.address.state
                            ? oldUserDetails.address.state
                            : '',
                    city:
                        oldUserDetails.address && oldUserDetails.address.city
                            ? oldUserDetails.address.city
                            : '',
                },
                zipCode: oldUserDetails.zip ? oldUserDetails.zip : '',
            };
        }

        return oldUserDetails;
    }

    static getPreferredUserDetailsFromUrlOverStore() {
        const userDetailsFromUrl = UserDataManager.getUserDetailsFromUrl();
        const userDetailsFromStore = UserDataManager.getUserDetailsFromStore();
        const preferredUserDetails = {
            firstName: userDetailsFromUrl.firstName || userDetailsFromStore.firstName,
            lastName: userDetailsFromUrl.lastName || userDetailsFromStore.lastName,
            email: userDetailsFromUrl.email || userDetailsFromStore.email,
            gender: userDetailsFromUrl.gender || userDetailsFromStore.gender,
            dob: userDetailsFromUrl.dob || userDetailsFromStore.dob,
            phone: userDetailsFromUrl.phone || userDetailsFromStore.phone,
            addr: {
                line1: userDetailsFromUrl.addr.line1 || userDetailsFromStore.addr.line1,
                line2: userDetailsFromUrl.addr.line2 || userDetailsFromStore.addr.line2,
                city: userDetailsFromUrl.addr.city || userDetailsFromStore.addr.city,
                state: userDetailsFromUrl.addr.state || userDetailsFromStore.addr.state,
            },
            zipCode: userDetailsFromUrl.zipCode || userDetailsFromStore.zipCode,
        };

        return preferredUserDetails;
    }

    static getPreferredUserDetailsFromStoreOverUrl() {
        const userDetailsFromUrl = UserDataManager.getUserDetailsFromUrl();
        const userDetailsFromStore = UserDataManager.getUserDetailsFromStore();
        const preferredUserDetails = {
            firstName: userDetailsFromStore.firstName || userDetailsFromUrl.firstName,
            lastName: userDetailsFromStore.lastName || userDetailsFromUrl.lastName,
            email: userDetailsFromStore.email || userDetailsFromUrl.email,
            gender: userDetailsFromStore.gender || userDetailsFromUrl.gender,
            dob: userDetailsFromStore.dob || userDetailsFromUrl.dob,
            phone: userDetailsFromStore.phone || userDetailsFromUrl.phone,
            addr: {
                line1: userDetailsFromStore.addr.line1 || userDetailsFromUrl.addr.line1,
                line2: userDetailsFromStore.addr.line2 || userDetailsFromUrl.addr.line2,
                city: userDetailsFromStore.addr.city || userDetailsFromUrl.addr.city,
                state: userDetailsFromStore.addr.state || userDetailsFromUrl.addr.state,
            },
            zipCode: userDetailsFromStore.zipCode || userDetailsFromUrl.zipCode,
        };

        return preferredUserDetails;
    }
}
