import Utils from 'common/utils';
import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import md5 from 'md5';
import convert from 'xml-js';
import { CommonHelper } from 'helpers';

/**
 *
 * @param {Object} prepingData data to be posted in request
 * @param {Object} storeKeyVal key value pair to be stored in session on success
 * @param {string} storeKeyVal.key
 * @param {string} storeKeyVal.value
 * @returns {undefined}
 */
const rewardAdvisorPreping = async (prepingData, storeKeyVal) => {
    if (Utils.isEmptyObj(prepingData)) {
        console.error('Preping data not found');

        return;
    }

    let oldPrepingExists;

    if (!Utils.isEmptyObj(storeKeyVal)) {
        oldPrepingExists = IdStore.fetchIdForKey(storeKeyVal.key);
    }

    if (!Utils.isNull(oldPrepingExists)) {
        IdStore.removeIdForKey(storeKeyVal);
    }

    const { email } = prepingData;
    const md5Email = md5(email);

    try {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'Reward_Advisor');
        await fetch(Constants.API_PROXY_URL, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                url: `${Constants.PREPING_URLS.REWARD_ADVISOR}${md5Email}`,
            }),
        })
            .then(str => str.text())
            .then(data => {
                const result = convert.xml2json(data, { compact: true, spaces: 4 });
                const res = JSON.parse(result);

                if (res && res.response.status._text === 'accepted') {
                    IdStore.storeIdForKey(storeKeyVal.key, storeKeyVal.value);
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_SUCCESS,
                        'Reward_Advisor',
                    );
                } else {
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_FAILURE,
                        'Reward_Advisor',
                        {
                            error: `preping failed`,
                        },
                    );
                }
            });
    } catch (e) {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'Reward_Advisor', {
            error: `error while sending preping: ${e.message}`,
        });
        console.log(e);
    }
};

export { rewardAdvisorPreping };
