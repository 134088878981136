import { StringHelper } from 'helpers';

const textLength = (maxLength, string) => {
    if (StringHelper.isNull(string)) return true;

    return string.length <= maxLength;
};

const isValidEmail = email => {
    if (StringHelper.isNull(email)) return false;

    if (
        !(
            /^[^\s@]+@[^\s@]+\.[^\s@]{2,5}$/.test(email) &&
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)
        )
    ) {
        return false;
    }

    return true;
};

export { textLength, isValidEmail };
