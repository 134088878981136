import Utils from 'common/utils';
import Constants from 'common/constants';
import UserDataValidator from 'common/userDataValidator';
import DateUtils from 'common/DateUtils';

export default class UserDetails {
    constructor(userDetailsObj) {
        if (Utils.isNull(userDetailsObj)) {
            userDetailsObj = {};
        }
        this.evs = userDetailsObj['evs'] ? userDetailsObj['evs'] : null;
        this.emailId = userDetailsObj['email'];
        this.firstName = userDetailsObj['first_name'];
        this.lastName = userDetailsObj['last_name'];
        this.gender = userDetailsObj['gender'];

        if (false === Utils.isNull(userDetailsObj['zip'])) {
            this.location = {
                zip: userDetailsObj['zip'],
            };
        }
        this.interests = userDetailsObj['interests'];
        this.dob = userDetailsObj['dob'];

        if (typeof this.dob === 'undefined') {
            this.dob = null;
        }

        this.phoneNumber = userDetailsObj['phone_number'];

        if (typeof this.phoneNumber === 'undefined') {
            this.phoneNumber = null;
        }

        this.contactConsent = 0;
        const contactConsentStr = userDetailsObj['contact_consent'];

        if (false === Utils.isEmptyStr(contactConsentStr) && contactConsentStr === '1') {
            this.contactConsent = 1;
        }

        this.emailConsent = 0;
        const emailConsentStr = userDetailsObj['opt_in_email'];

        if (false === Utils.isEmptyStr(emailConsentStr) && emailConsentStr === '1') {
            this.emailConsent = 1;
        }

        this.address = userDetailsObj['address'];

        if (typeof this.address === 'undefined') {
            this.address = null;
        }

        if (false === Utils.isEmptyStr(this.dob)) {
            const newDobVal = DateUtils.convertDateBetweenFormats(
                this.dob,
                Constants.USER.DOB_USER_INPUT_DATE_FORMAT,
                Constants.USER.DOB_API_DATE_FORMAT,
            );
            this.dob = newDobVal;
        }
    }

    /**
     * validates all the fields of the user details.
     * If no issue, returns null.
     * If issue, then returns following object -
     * {
     *      "field": "message"
     * }
     *
     * Validation rules
     * location - mandatory, > 1 char
     * interests - optional, object
     *
     */
    validate() {
        const validationErrs = {};
        let errMsg = null;
        // Location
        errMsg = UserDataValidator.isValidLocation(this.location);

        if (false === Utils.isEmptyStr(errMsg)) {
            validationErrs.location = errMsg;
        } else if (false === Utils.isEmptyStr(this.location.zip)) {
            errMsg = UserDataValidator.isValidZipCode(this.location.zip);

            if (false === Utils.isEmptyStr(errMsg)) {
                validationErrs.location = errMsg;
            }
        }

        if (Utils.isEmptyObj(validationErrs)) {
            return null;
        }

        return validationErrs;
    }

    /**
     * Format the current object to be sent as data in the event
     */
    getFormattedObjForEvent() {
        const eventObj = {};
        eventObj['first_name'] = this.firstName;
        eventObj['last_name'] = this.lastName;
        eventObj['email'] = this.emailId;
        eventObj['phone_number'] = this.phoneNumber;
        eventObj['address'] = JSON.stringify(this.address);
        eventObj['optxt'] = Constants.TCPA_OPTIN_TEXT;
        eventObj['zip'] = (this.location && this.location.zip) || '';

        let dob = this.dob;

        if (false === Utils.isEmptyStr(dob)) {
            dob = DateUtils.convertDateBetweenFormats(
                dob,
                Constants.USER.DOB_API_DATE_FORMAT,
                Constants.USER.DOB_USER_INPUT_DATE_FORMAT,
            );
        }
        eventObj['dob'] = dob;

        /*
         * non-string entries
         *eventObj["gender"] = `${this.gender}`
         */

        /*
         * Add all the location related entries
         * NOTE: Assumption is the keys match. It is, till now :)
         */
        if (false === Utils.isEmptyObj(this.location)) {
            ['city', 'zip', 'state', 'country'].forEach(key => {
                const val = this.location[key];

                if (false === Utils.isEmptyStr(val)) {
                    eventObj[key] = `${val}`;
                }
            });
        }

        return eventObj;
    }
}
