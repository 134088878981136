import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import { CommonHelper } from 'helpers';

// eslint-disable-next-line max-lines-per-function
export const pentiusPreping = async email => {
    const sanitizedEmail = email && typeof email === 'string' ? email.trim().toLowerCase() : '';

    try {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'CreditScore_RDS');

        const res = await fetch(Constants.API_PROXY_URL, {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                url: `${Constants.PREPING_URLS.PENTIUS}?username=ConnexusFSNCredNon_cpa&password=connexus2020&email=${sanitizedEmail}`,
            }),
        }).then(res => res.json());

        if (res && res.status)
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.PENTIUS_PREPING, res.status);

        if (res && res.status === '0') {
            CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SUCCESS, 'CreditScore_RDS');
        } else {
            CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'CreditScore_RDS');
        }
    } catch (e) {
        console.log(e);

        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'CreditScore_RDS', {
            error: `error while sending preping: ${e.message}`,
        });
    }
};
