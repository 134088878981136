import React from 'react';
import { useStyles } from './footer.styles';
import { FooterLinks } from './links';
import { CommonHelper } from 'helpers';
import { Link } from 'react-router-dom';
import { ExternalLinks } from '../../../constants/links';
import MUILink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Messages } from '../../../strings';
import ReactHtmlParser from 'react-html-parser';

/**
 * Generate Links for footer
 * @returns React Component with footer links
 */
const getLinks = linkClass => {
    if (CommonHelper.isNull(FooterLinks) || 0 === FooterLinks.length) return <></>;

    return (
        <>
            {FooterLinks.map(value => {
                return ExternalLinks.includes(value.text) ? (
                    <MUILink
                        className={linkClass}
                        href={value.link}
                        key={value.text}
                        onClick={value.onClick}
                        rel="noreferrer"
                        target="_blank">
                        {value.text}
                    </MUILink>
                ) : (
                    <Link
                        aria-label={value.text}
                        className={linkClass}
                        key={value.text}
                        onClick={value.onClick}
                        title={value.text}
                        to={value.link}>
                        {value.text}
                    </Link>
                );
            })}
        </>
    );
};

/**
 * App Footer
 * @param {*} props
 * @returns Footer
 */
export const Footer = props => {
    const classes = useStyles(props);

    return (
        <footer>
            <Grid className={`${classes.container}`}>
                <div className={classes.links}>{getLinks(classes.link)}</div>
                <Typography align="center" className={classes.copyRight}>
                    &copy;{ReactHtmlParser(Messages.COPYRIGHT_MESSAGE(new Date().getFullYear()))}
                </Typography>
            </Grid>
        </footer>
    );
};
