import Utils from 'common/utils';
import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import { CommonHelper } from 'helpers';

/**
 *
 * @param {Object} prepingData data to be posted in request
 * @param {Object} storeKeyVal key value pair to be stored in session on success
 * @param {string} storeKeyVal.keys
 * @param {string} storeKeyVal.value
 * @returns {undefined}
 */
const rmgPreping = async (prepingData, storeKeyVal) => {
    if (Utils.isEmptyObj(prepingData)) {
        console.error('Preping data not found');

        return;
    }

    let oldPrepingExists;

    if (!Utils.isEmptyObj(storeKeyVal)) {
        oldPrepingExists = IdStore.fetchIdForKey(storeKeyVal.key);
    }

    if (!Utils.isNull(oldPrepingExists)) {
        IdStore.removeIdForKey(storeKeyVal);
    }

    const { email } = prepingData;

    try {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'RMG_GroupOnePlatinum');
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'RMG_MeritPlatinum');

        await fetch(Constants.API_PROXY_URL, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                url: `${Constants.PREPING_URLS.RMG}key=z36JsNWV8AhQv2kWb7n0JA5l&email=${email}`,
            }),
        })
            .then(str => str.text())
            .then(data => {
                if (data && data === 'Success') {
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_SUCCESS,
                        'RMG_GroupOnePlatinum',
                    );

                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_SUCCESS,
                        'RMG_MeritPlatinum',
                    );

                    if (!Utils.isEmptyObj(storeKeyVal)) {
                        storeKeyVal.keys.forEach(key => {
                            IdStore.storeIdForKey(key, storeKeyVal.value);
                        });
                    }
                } else {
                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_FAILURE,
                        'RMG_GroupOnePlatinum',
                        {
                            error: `error while sending preping: ${data}`,
                        },
                    );

                    CommonHelper.triggerPreppingEvent(
                        Constants.EVENT.PREPING_FAILURE,
                        'RMG_MeritPlatinum',
                        {
                            error: `error while sending preping: ${data}`,
                        },
                    );

                    if (!Utils.isEmptyObj(storeKeyVal)) {
                        storeKeyVal.keys.forEach(key => {
                            IdStore.storeIdForKey(key, 'failed');
                        });
                    }
                }
            });
    } catch (e) {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'RMG_GroupOnePlatinum', {
            error: `error while sending preping: ${e.message}`,
        });
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'RMG_MeritPlatinum', {
            error: `error while sending preping: ${e.message}`,
        });
        console.log(e);
    }
};

export { rmgPreping };
