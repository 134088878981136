export const KeyMap = {
    KwImageSrc: 'KwImageSrc',
    RgStaticKwImage: 'RgStaticKwImage',
    OfferPathHeaderDesignTest: 'OfferPathHeaderDesignTest',
    OfferPathNewUITest: 'OfferPathNewUITest',
    GenderOptimizeTest: 'GenderOptimizeTest',
    FulfillmentPageDesignTest: 'FulfillmentPageDesignTest',
    PIITest: 'PIITest',
    PIITestForPPR: 'PIITestForPPR',
    EduQuestion: 'EduQuestion',
    DriverOfferPathTargeting: 'DriverOfferPathTargeting',
    MultipleOfferPath: 'MultipleOfferPath',
    MultiBrandKWLogo: 'MultiBrandKWLogo',
    ListingsJobDedup: 'ListingsJobDedup',
    SMSRedirectTest: 'SMSRedirectTest',
    PhoneNoDeepValidation: 'PhoneNoDeepValidation',
    FbBgFlow: 'FbBgFlow',
    FbGoogleEmailInterstitial: 'FbGoogleEmailInterstitial',
    SkipToOfferPathSources: 'SkipToOfferPathSources',
    SkipToOfferPathChannel: 'SkipToOfferPathChannel',
    OldUserSkipToOfferPath: 'OldUserSkipToOfferPath',
    GoogleDisplaySkipToOfferPath: 'GoogleDisplaySkipToOfferPath',
    TaboolaLander: 'TaboolaLander',
    MAX_PROMO_VALUE: 'MAX_PROMO_VALUE',
    OldUserJobcaseOffer: 'OldUserJobcaseOffer',
    NotifyZipToOffers: 'NotifyZipToOffers',
    InterstitialBetweenOfferPath: 'Interstitial Between Offer Path',
    Back2ListingsMidPathRedirection: 'back2ListingsMidPathRedirection',
    AllInboxEmailLander: 'AllInboxEmailLander',
    RegThree: 'RegThree',
    GoogleDisplayKw: 'GoogleDisplayKw',
    RgKwNewFlow: 'RgKwNewFlow',
    WfhAmazon: 'WfhAmazon',
    MarketingPartners: 'MarketingPartners',
    TCPAMarketingPartners: 'TCPAMarketingPartners',
    TCPMarketingPartnersExtra: 'TCPAMarketingPartnersExtra',
    BaristaStarbucksKw: 'BaristaStarbucksKw',
    ListingsInBg: 'ListingsInBg',
    ListingsInBgHybridSurveyFlow: 'ListingsInBgHybridSurveyFlow',
    ListingsInBgHybridOffersFlow: 'ListingsInBgHybridOffersFlow',
    ListingsInBgMidpathOffers: 'ListingsInBgMidpathOffers',
    ListingsInBgPrelanderKw: 'ListingsInBgPrelanderKw',
    ListingsBgXmlTest: 'ListingsBgXmlTest',
    GoogleProgressBarTest: 'GoogleProgressBarTest',
    ListingsInBgWithZipUpwardOpt: 'ListingsInBgWithZipUpwardOpt',
    AIBEMJobCategoryTest: 'AIBEMJobCategoryTest',
    NoSkipToOffersTest: 'NoSkipToOffersTest',
    ListingsBgXmlStaticImageTest: 'ListingsBgXmlStaticImageTest',
    ListingsBgXmlTestMobileImage: 'ListingsBgXmlTestMobileImage',
    ListingsBgXmlTestTabletImage: 'ListingsBgXmlTestTabletImage',
    ListingsBgXmlTestWebImage: 'ListingsBgXmlTestWebImage',
    AIBEMLander: 'AIBEMLander',
    SurveyQueVaraition: 'SurveyQueVaraition',
    JobcaseCoregUI: 'JobcaseCoregUI',
    RgKeyword: 'RgKeyword',
    XMLPartTimeBG: 'XMLPartTimeBG',
    RepeatUser: 'RepeatUser',
    FBSkipToOffers: 'FBSkipToOffers',
    KWSelectorRGLandingPageTest: 'KWSelectorRGLandingPageTest',
    GoogleKWSelectorLandingPage: 'GoogleKWSelectorLandingPage',
    GoogleKWSelectorLanderMapping: 'GoogleKWSelectorLanderMapping',
    Reg2Optimize: 'Reg2Optimize',
    PrePopKWSelectorLanderTest: 'PrePopKWSelectorLanderTest',
    UpwardEmailAndRegPageOptimization: 'UpwardEmailAndRegPageOptimization',
    AllInboxSMSLander: 'AllInboxSMSLander',
    SMSLanderJTMapping: 'SMSLanderJTMapping',
    RGDropdownLander: 'RGDropdownLander',
    XMLListingBgSkipWithoutZip: 'XMLListingBgSkipWithoutZip',
    EduQuestionVar: 'EduQuestionVar',
    AWKSMultiBrandLander: 'AWKSMultiBrandLander',
    AWKSMultiBrandLanderLogos: 'AWKSMultiBrandLanderLogos',
    SeniorJobsFacebook: 'SeniorJobsFacebook',
    MultiBrandLander: 'MultiBrandLander',
    MultiBrandLanderLogos: 'MultiBrandLanderLogos',
    MultiBrandKwOptions: 'MultiBrandKwOptions',
    UpwardEmailOptInTest: 'UpwardEmailOptInTest',
    ListingsInBGWithWhiteBGTest: 'ListingsInBGWithWhiteBGTest',
    EventsToTrack: 'EventsToTrack',
    SnapchatMultiBrand: 'SnapchatMultiBrand',
    SnapchatMultiBrandLogoList: 'SnapchatMultiBrandLogoList',
    OnlineJobsToWorkFromHomeForGoogle: 'OnlineJobsToWorkFromHomeForGoogle',
    ForceFeedToUpdateWith: 'ForceFeedToUpdateWith',
    PushOptInPlacementTest: 'PushOptInPlacementTest',
    PushOptInPlacement: 'PushOptInPlacement',
    GCS_PHONE_TITLE: 'GCS_PHONE_TITLE',
    GCS_INTERMEDIATE_TITLE: 'GCS_INTERMEDIATE_TITLE',
    GCS_SURVEYPATH_TITLE: 'GCS_SURVEYPATH_TITLE',
    GCS_OFFERWALL_HEADER: 'GCS_OFFERWALL_HEADER',
    GCS_OFFERWALL_SUBHEADER: 'GCS_OFFERWALL_SUBHEADER',
    GCS_EACHSCREEN_FOOTER: 'GCS_EACHSCREEN_FOOTER',
    GCS_LISTINGPAGE_TEXT: 'GCS_LISTINGPAGE_TEXT',
    GCS_TRENDING_PROGRESS_BAR_TEXT: 'GCS_TRENDING_PROGRESS_BAR_TEXT',
    GCS_INTERMITTENT_GIFT_CARD_READY: 'GCS_INTERMITTENT_GIFT_CARD_READY',
    GCS_NEW_EMAIL_TITLE: 'GCS_NEW_EMAIL_TITLE',
    GCS_COMPLIANCE_SUB_HEADING: 'GCS_COMPLIANCE_SUB_HEADING',
    LANDER_HEADER_ON_EMAIL: 'LANDER_HEADER_ON_EMAIL',
    GCS_REG1_SUB_HEADING: 'GCS_REG1_SUB_HEADING',
    GCS_REG2_SUB_HEADING: 'GCS_REG2_SUB_HEADING',
    // RDS Features
    RDS_REG_LEADS: 'RDS_REG_LEADS',
    RDS_SHOW_ME_PAGE_OFFER_WALL_ID: 'RDS_SHOW_ME_PAGE_OFFER_WALL_ID',
    RDS_BACK_BUTTON_OFFER_WALL_ID: 'RDS_BACK_BUTTON_OFFER_WALL_ID',
    RDS_CUSTOM_QUESTION_CONFIG: 'RDS_CUSTOM_QUESTION_CONFIG',
    RDSSurveyDependentQuestions: 'RDSSurveyDependentQuestions',
    AutoInsuranceTest: 'AutoInsuranceTest',
    SurveyJobCategoryQuestionTest: 'SurveyJobCategoryQuestionTest',
    ExxonCreative: 'ExxonCreative',
    CampaignDetailMapping: 'CampaignDetailMappingRDS',
    APIRepeatUserFlow: 'APIRepeatUserFlow',
    SweepsSlotLander: 'SweepsSlotLander',
    RDSMidPathBackgroundTest: 'RDSMidPathBackgroundTest',
    RDSMidPathBackgroundImage: 'RDSMidPathBackgroundImage',
    RDS_LANDER_BANNER_TITLE: 'RDS_LANDER_BANNER_TITLE',
    RDS_LANDER_TIMER_MSG: 'RDS_LANDER_TIMER_MSG',
    RDS_LANDER_CTA: 'RDS_LANDER_CTA',
    RDS_REG1_FORM_SUBTITLE: 'RDS_REG1_FORM_SUBTITLE',
    RDS_REG1_CTA: 'RDS_REG1_CTA',
    RDS_DETAILS_FORM_CTA: 'RDS_DETAILS_FORM_CTA',
    RDS_DETAILS_FORM_HEADER: 'RDS_DETAILS_FORM_HEADER',
    RDS_OFFERS_HEADER: 'RDS_OFFERS_HEADER',
    RDS_SA_OFFER: 'RDS_SA_OFFER',
    RDS_SA_OFFER_SMALL: 'RDS_SA_OFFER_SMALL',
    RDS_PG_PROGRESS_PT_1: 'RDS_PG_PROGRESS_PT_1',
    RDS_PG_PROGRESS_PT_2_SHOW_ME: 'RDS_PG_PROGRESS_PT_2_SHOW_ME',
    RDS_PG_PROGRESS_PT_2_OFFERS: 'RDS_PG_PROGRESS_PT_2_OFFERS',
    RDS_PG_PROGRESS_PT_3: 'RDS_PG_PROGRESS_PT_3',
    RDS_SHOW_ME_HEADER: 'RDS_SHOW_ME_HEADER',
    RDS_SHOW_ME_HEADER_STANDALONE: 'RDS_SHOW_ME_HEADER_STANDALONE',
    RDS_STAND_ALONE_EXTRA_OFFERS: 'RDS_STAND_ALONE_EXTRA_OFFERS',
    RDS_ENTRY_HEADER: 'RDS_ENTRY_HEADER',
    RDS_ENTRY_NOT_CONFIRMED: 'RDS_ENTRY_NOT_CONFIRMED',
    RDS_ENTRY_EXTRA_OFFERS: 'RDS_ENTRY_EXTRA_OFFERS',
    RDS_BACK_BTN_HEADER: 'RDS_BACK_BTN_HEADER',
    RDS_GO_BACK_BTN: 'RDS_GO_BACK_BTN',
    RDS_GO_BTN: 'RDS_GO_BTN',
    RDS_SURVEY_HEADER: 'RDS_SURVEY_HEADER',
    RDS_EVERYJOB_SUBTITLE: 'RDS_EVERYJOB_SUBTITLE',
    RDS_EVERYJOB_TITLE: 'RDS_EVERYJOB_TITLE',
    RDS_CLERGY_TITLE: 'RDS_CLERGY_TITLE',
    customQuestionsListRDS: 'customQuestionsListRDS',
    EveryJobForMeSweepsTest: 'EveryJobForMe_Sweeps_Test',
    ClergyCreativeTest: 'ClergyCreativeTest',
    RDS_FINAL_EXP_BODY: 'RDS_FINAL_EXP_BODY',
    RDS_FINAL_EXP_TITLE: 'RDS_FINAL_EXP_TITLE',
    RDS_FINAL_EXP_TCPA: 'RDS_FINAL_EXP_TCPA',
    customizableSubtitle: 'RDS_MULTIVALUE_QUESTION_SUB_TITLE',
    EquotoFinalExpenseTest: 'EquotoFinalExpenseTest',
    RDS_SURVEY_LOADER_STEPS: 'RDS_SURVEY_LOADER_STEPS',
    RDS_SURVEY_LOADER_TITLE: 'RDS_SURVEY_LOADER_TITLE',
    RDS_STAND_ALONE_OFFER_WALL_ID: 'RDS_STAND_ALONE_OFFER_WALL_ID',
    SweepsLander: 'SweepsLander',
    SurveyProgressbarTest: 'SurveyProgressbarTest',
    OffersProgressbarTest: 'OffersProgressbarTest',
    REG2_TCPA_OPTION_TEST: 'REG2_TCPA_OPTION_TEST',
    RDS_BACK_BUTTON_OFFER_WALL_CONFIG: 'RDS_BACK_BUTTON_OFFER_WALL_CONFIG',
    RDS_SHOW_ME_PAGE_OFFER_WALL_CONFIG: 'RDS_SHOW_ME_PAGE_OFFER_WALL_CONFIG',
    YA_SOLUTIONS_CAMPAIGNS: 'YA_SOLUTIONS_CAMPAIGNS',
    RDS_STAND_ALONE_OFFER_WALL_CONFIG: 'RDS_STAND_ALONE_OFFER_WALL_CONFIG',
    RDS_ENTRY_OFFER_WALL_CONFIG: 'RDS_ENTRY_OFFER_WALL_CONFIG',
    RDS_CONFIRMATION_PAGE: 'RDS_CONFIRMATION_PAGE',
    YA_SOLUTIONS_SWEEPS_TCPA: 'YA_SOLUTIONS_SWEEPS_TCPA',
    YA_SOL_H2: 'YA_SOL_H2',
    YA_SOL_H3: 'YA_SOL_H3',
    SweepImageAnimation: 'SweepImageAnimation',
    SPIN_NOW_INITIAL: 'SPIN_NOW_INITIAL',
    RDS_SM_LANDER_BANNER_TITLE: 'RDS_SM_LANDER_BANNER_TITLE',
    OFFER_NOT_AVAILABLE_HEAD: 'OFFER_NOT_AVAILABLE_HEAD',
    OFFER_NOT_AVAILABLE_SUB_HEAD: 'OFFER_NOT_AVAILABLE_SUB_HEAD',
    DISABLE_RECAPTCHA_REDIRECT: 'DISABLE_RECAPTCHA_REDIRECT',
    SurveyQuestionAlignmentTest: 'SurveyQuestionAlignmentTest',
    RDS_CONFIRMATION_PAGE_FIELDS: 'RDS_CONFIRMATION_PAGE_FIELDS',
    DEV_FEATURE_SHOULD_VALIDATE_IFRAME_HTML: 'DEV_FEATURE_SHOULD_VALIDATE_IFRAME_HTML',
    RDS_NEW_LANDER: 'RDS_NEW_LANDER',
    HALLOWEEN_THEME: 'HALLOWEEN_THEME',
    SP_SA_OFFER_SMALL: 'SP_SA_OFFER_SMALL',
    SP_SA_OFFER: 'SP_SA_OFFER',
    SP_ENTRY_EXTRA_OFFERS: 'SP_ENTRY_EXTRA_OFFERS',
    PROGRESS_BAR_VARIATION: 'PROGRESS_BAR_VARIATION',
};
