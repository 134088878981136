import { createMuiTheme } from '@material-ui/core/styles';

// generated using https://in-your-saas.github.io/material-ui-theme-editor/
export const theme = createMuiTheme({
    palette: {
        common: {
            black: 'rgba(20, 21, 22, 1)',
            white: 'rgba(254, 254, 254, 1)',
        },
        background: { paper: '#fff', default: '#F9F9F9' },
        primary: {
            light: 'rgba(222, 243, 216, 1)',
            main: 'rgba(78, 166, 53, 1)',
            dark: 'rgba(45, 96, 31, 1)',
            contrastText: 'rgba(254, 254, 254, 1)',
        },
        secondary: {
            light: 'rgba(61, 153, 250, 1)',
            main: 'rgba(20, 130, 247, 1)',
            dark: 'rgba(8, 109, 218, 1)',
            contrastText: 'rgba(254, 254, 254, 1)',
        },
        error: {
            light: 'rgba(255, 205, 210, 1)',
            main: 'rgba(211, 47, 47, 1)',
            dark: 'rgba(183, 28, 28, 1)',
            contrastText: '#fff',
        },
        text: {
            primary: 'rgba(20, 21, 22, 1)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 10,
                border: 0,
                padding: '14px 56px',
            },
        },
    },
    typography: {
        fontFamily: 'Rubik',
    },
});
