import { IdStore } from 'dataStore/index';
import Constants from 'common/constants';
import Utils from './utils';

// Common

export const BASE_API_URL = () => {
    if (!Utils.isTestEnv()) return 'https://api.cnxdserv.com';

    const baseAPIUrl = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.BASE_API_URL);

    return !Utils.isEmptyStr(baseAPIUrl) ? baseAPIUrl : 'https://staging.api.cnxdserv.com';
};

export const PUBLISHER_COST_BASE_URL = () => {
    if (!Utils.isTestEnv()) return 'https://publisher-cost-reporting-qvznpy2hwq-uc.a.run.app/';

    const basePublisherCostUrl = IdStore.fetchIdForKey(
        Constants.ID_STORE_KEYS.PUBLISHER_COST_BASE_URL,
    );

    return !Utils.isEmptyStr(basePublisherCostUrl)
        ? basePublisherCostUrl
        : 'https://publisher-cost-reporting-staging-qvznpy2hwq-uc.a.run.app';
};

export const CONFIGURATION_PROVIDER = () => {
    if (!Utils.isTestEnv()) return 'https://config-manager-qvznpy2hwq-uc.a.run.app/c/api/v2/config';

    const url = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.CONFIGURATION_PROVIDER);

    return !Utils.isEmptyStr(url)
        ? url
        : 'https://config-manager-stg-qvznpy2hwq-uc.a.run.app/c/api/v2/config';
};

export const PROXY_PROVIDER_URL = () => {
    if (!Utils.isTestEnv()) return 'https://api.cnxdserv.com/a/api/v2/proxy';

    const url = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.PROXY_PROVIDER_URL);

    return !Utils.isEmptyStr(url) ? url : 'https://staging.api.cnxdserv.com/a/api/v2/proxy';
};

export const GIFT_CARD_SERVICE_BASE_URL = () => {
    if (!Utils.isTestEnv()) return '/api/v1/rewardStatus';

    const url = IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GIFT_CARD_SERVICE_BASE_URL);

    return !Utils.isEmptyStr(url)
        ? url
        : 'https://gift-card-service-stg-qvznpy2hwq-ue.a.run.app/api/v1/rewardStatus';
};

const GIFT_CARD_URL_SERVICE_BASE_URL = Utils.isLocalEnv()
    ? 'https://gift-urls-service-stg-qvznpy2hwq-ue.a.run.app'
    : '';
// Survey related
const SURVEY_BASE_URL = () => `${BASE_API_URL()}/s`;
const SURVEY_BASE_V2_URL = () => `${SURVEY_BASE_URL()}/api/v2`;

// User related
const USER_BASE_URL = () => `${BASE_API_URL()}/u`; // `${BASE_FUNC_URL}/survey_contour/`
const SESSION_EP = '/api/v1/session';
const RECAPTCHA_EP = '/api/v1/recaptcha';
const USER_DETAILS_EP = '/api/v2/user';
const SURVEY_COMPLETED_EP = '/api/v1/sr';

const ZIP_API_EP = '/u/api/v1/z2l';
const REWARD_STATUS_EP = 'email';

/*
 * Events related
 * const EVENT_BASE_URL = BASE_API_URL
 * const EVENT_EP = "e"
 */

// Temp Events URl -
const EVENT_BASE_URL = () => BASE_API_URL();
const EVENT_EP = '/e/';

// Ads related
const BASE_ADS_URL = () => `${BASE_API_URL()}/a`;
const ADS_EP = '/api/v4/sa';
const OFFER_WALL_EP = 'api/v4/ow';
const OFFER_WALL_EP_V2 = '/a/api/v4/sa/ow';
const AD_UNIT_EP = '/api/v4/au';
const ADS_CLICKED = '/api/v4/ads/click';

// Jobs related
const JOB_RESULTS = '/api/v2/jobResults';
const PUSH_EP = 'api/v1/push_subscription';
const JOBS_API = 'https://ads.dailywebtopics.com/xmlApi';

// User Related
const EMAIL_VERIFICATION = 'api/v1/email_verification';
const PHONE_VERIFICATION = 'api/v1/phone_verification';

// Email Decryption
const PUBLISHER_DECRYPTION = 'publisherDecryption';

// Topresume upload
const TOPRESUME_UPLOAD = '/api/v2/topresume/upload';

export default class UrlManager {
    static getSurveyUrlForArgs(surveyArgs) {
        let pathId = surveyArgs.surveyPathId;

        if (Utils.isEmptyStr(pathId)) {
            pathId = 'unknown';
        }

        // NOTE: Doing this since js and ES6 does not have in-built string formatter
        return Utils.joinUrlPaths([SURVEY_BASE_URL(), 'surveypaths', pathId, 'all']);
    }

    static getSurveyPathUrlForId(surveyId) {
        if (Utils.isEmptyStr(surveyId)) {
            surveyId = 'unknown';
        }

        // NOTE: Doing this since js and ES6 does not have in-built string formatter
        return Utils.joinUrlPaths([SURVEY_BASE_V2_URL(), 'surveys', surveyId, 'path']);
    }

    static getUserDetailsUrl() {
        return Utils.joinUrlPaths([USER_BASE_URL(), USER_DETAILS_EP]);
    }

    /**
     * @deprecated Not being used currently. Can be safely removed once this api is removed.
     */
    static getSurveyCompletedUrl() {
        return Utils.joinUrlPaths([USER_BASE_URL(), SURVEY_COMPLETED_EP]);
    }

    static getSessionUrl() {
        return Utils.joinUrlPaths([USER_BASE_URL(), SESSION_EP]);
    }

    static getEventsUrl() {
        return `${Utils.joinUrlPaths([EVENT_BASE_URL(), EVENT_EP])}/`;
    }

    static getAdsUrl() {
        return Utils.joinUrlPaths([BASE_ADS_URL(), ADS_EP]);
    }

    static getOfferWallUrl(offerWallId) {
        return Utils.joinUrlPaths([BASE_ADS_URL(), OFFER_WALL_EP, offerWallId]);
    }

    static getOfferWallUrlV2() {
        return Utils.joinUrlPaths([BASE_API_URL(), OFFER_WALL_EP_V2]);
    }

    static getAdUnitUrl() {
        return Utils.joinUrlPaths([BASE_ADS_URL(), AD_UNIT_EP]);
    }

    static getAdClickUrl() {
        return Utils.joinUrlPaths([BASE_ADS_URL(), ADS_CLICKED]);
    }

    static getSingleAdUrl(path) {
        return Utils.joinUrlPaths([BASE_ADS_URL(), path]);
    }

    static getJobsUrl() {
        return Utils.joinUrlPaths([BASE_ADS_URL(), JOB_RESULTS]);
    }

    static getPushSubscriptionUrl() {
        return Utils.joinUrlPaths([USER_BASE_URL(), PUSH_EP]);
    }

    static getEmailVerificationUrl() {
        return Utils.joinUrlPaths([USER_BASE_URL(), EMAIL_VERIFICATION]);
    }

    static getPhoneVerificationUrl() {
        return Utils.joinUrlPaths([USER_BASE_URL(), PHONE_VERIFICATION]);
    }

    static getSponsoredAds() {
        return JOBS_API;
    }

    static getZipUrl() {
        return Utils.joinUrlPaths([BASE_API_URL(), ZIP_API_EP]);
    }

    static getPublisherDecryptionUrl() {
        return Utils.joinUrlPaths([PUBLISHER_COST_BASE_URL(), PUBLISHER_DECRYPTION]);
    }

    static getTopresumeUploadUrl() {
        return Utils.joinUrlPaths([BASE_ADS_URL(), TOPRESUME_UPLOAD]);
    }

    static getConfigurationProviderUrl() {
        return `${CONFIGURATION_PROVIDER()}`;
    }

    static getGiftCardRewardStatusUrl(emailId) {
        return Utils.joinUrlPaths([GIFT_CARD_SERVICE_BASE_URL(), REWARD_STATUS_EP, emailId]);
    }

    static getProxyUrl() {
        return `${PROXY_PROVIDER_URL()}`;
    }

    static getOfferWallEntryValidatorUrl(surl) {
        return `${GIFT_CARD_URL_SERVICE_BASE_URL}/api/dowURL/v1/valid?surl=${surl}`;
    }

    static getReCaptchaUrl() {
        return Utils.joinUrlPaths([USER_BASE_URL(), RECAPTCHA_EP]);
    }
}
