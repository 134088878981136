import Utils from 'common/utils';
import { getFeatureMapValue } from './getFeatureValues';
import { FeatureMap, ConfigProvider } from 'providers';

// ------- Helpers ------- //
export const _filterInvalidSERPListings = listings =>
    listings.filter(item => {
        if (
            !Utils.isNull(item) &&
            !Utils.isNull(item.id) &&
            !Utils.isNull(item.position) &&
            !Utils.isNull(item.page)
        ) {
            return true;
        } else {
            console.error('Config: Invalid SERP listing configured', item);

            return false;
        }
    });

const _getOfferWallConfig = featureMap => {
    const feature = ConfigProvider.Features.getConfigAPIFeature(featureMap);
    let configValue;

    if (feature && feature.value && feature.value.length > 0) {
        configValue = feature.value.map(config => Utils.jsonSafeParse(config));
    }

    return configValue;
};

export const getRegNetSpendLeads = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDS_REG_LEADS));

export const getShowMeDealOfferWallId = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDS_SHOW_ME_PAGE_OFFER_WALL_ID));

export const getStandAloneOfferWallId = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDS_STAND_ALONE_OFFER_WALL_ID));

export const getOfferPageOfferWallId = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDS_BACK_BUTTON_OFFER_WALL_ID));

export const getRDSSurveyDependentQuestions = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDSSurveyDependentQuestions));

export const getRDSMidPathBackgroundImages = () => {
    return Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDSMidPathBackgroundImage));
};

export const getCustomConfigQuestions = () => {
    return Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.RDS_CUSTOM_QUESTION_CONFIG));
};

export const getYaSolutionsCampaigns = () => {
    return Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.YA_SOLUTIONS_CAMPAIGNS));
};

export const getYaSolutionsCampaignsTCPA = () => {
    return Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.YA_SOLUTIONS_SWEEPS_TCPA));
};

export const getBackButtonOfferWallConfig = () =>
    _getOfferWallConfig(FeatureMap.RDS_BACK_BUTTON_OFFER_WALL_CONFIG);

export const getEntryPageOfferWallConfig = () =>
    _getOfferWallConfig(FeatureMap.RDS_ENTRY_OFFER_WALL_CONFIG);

export const getShowMePageOfferWallConfig = () =>
    _getOfferWallConfig(FeatureMap.RDS_SHOW_ME_PAGE_OFFER_WALL_CONFIG);

export const getStandAlonePageOfferWallConfig = () =>
    _getOfferWallConfig(FeatureMap.RDS_STAND_ALONE_OFFER_WALL_CONFIG);

export const getPushOptInPlacementValue = () =>
    Utils.jsonSafeParse(getFeatureMapValue(FeatureMap.PushOptInPlacement));
