const LOG_PREFIX = '[notification-manager] ';
const STATUS_CODE_MAP = {
    default: 0,
    granted: 1,
    denied: 2,
    unsupported: 4,
};
export default class NotificationManager {
    static getNotificationStatusCode() {
        try {
            // granted=1, denied=2, default=0, not supported=4
            return STATUS_CODE_MAP[Notification.permission] || STATUS_CODE_MAP.default;
        } catch {
            return STATUS_CODE_MAP.unsupported;
        }
    }

    static getNotificationPermissionStatus() {
        console.log('Notification: status is ', Notification.permission);

        return Notification.permission;
    }

    /*
     *   Returns true if notification permission is granted, false for default and block status
     */
    static isNotificationPermissionGranted() {
        if (this.getNotificationPermissionStatus() === 'granted') {
            return true;
        }

        return false;
    }

    /*
     *   Returns true if notification permission is default, false for granted and block status
     */
    static canRequestPermission() {
        if (this.getNotificationPermissionStatus() === 'default') {
            console.log("Notification: status is default, can't show");

            return true;
        }

        return false;
    }

    /*
     *   Returns true if browser supports notification
     */
    static doesBrowserSupportsNotification() {
        if ('Notification' in window && navigator.serviceWorker) {
            return true;
        }

        return false;
    }

    /*
     *   Returns true if custom notification dialog is to be shown
     */
    static shouldShowNotificationDialog() {
        if (false === this.doesBrowserSupportsNotification()) {
            console.log(LOG_PREFIX, 'Browser does not support notification');

            return false;
        }

        return this.canRequestPermission();
    }
}
