import Constants from 'common/constants';

const EVENT_CONSTANTS = {
    EVENT_ID_KEY: 'evt_id',
    EVENT_STORE_MAX_LIMIT: 10,
    ALLOWED_ID_VALS: [
        Constants.ID_STORE_KEYS.PUBLIC_IP,
        Constants.ID_STORE_KEYS.SESSION_ID,
        Constants.ID_STORE_KEYS.UUID,
        Constants.ID_STORE_KEYS.DEVICE_ID,
        Constants.ID_STORE_KEYS.LANDER_ID,
        Constants.ID_STORE_KEYS.LANDER_NAME,
        Constants.ID_STORE_KEYS.FLAVOR_NAME,
        Constants.ID_STORE_KEYS.LANDER_PATH_NAME,
        Constants.ID_STORE_KEYS.USER_ID,
        Constants.ID_STORE_KEYS.SURVEY_ID,
        Constants.ID_STORE_KEYS.SURVEY_PATH_ID,
        Constants.ID_STORE_KEYS.GEO_CITY,
        Constants.ID_STORE_KEYS.GEO_COUNTRY,
        Constants.ID_STORE_KEYS.GEO_REGION,
        Constants.ID_STORE_KEYS.GEO_ZIP_CODE,
        Constants.ID_STORE_KEYS.UTM_SOURCE,
        Constants.ID_STORE_KEYS.UTM_MEDIUM,
        Constants.ID_STORE_KEYS.UTM_CAMPAIGN,
        Constants.ID_STORE_KEYS.UTM_TERM,
        Constants.ID_STORE_KEYS.UTM_CONTENT,
        Constants.ID_STORE_KEYS.REFERRER,
        Constants.ID_STORE_KEYS.DOMAIN,
        Constants.ID_STORE_KEYS.CS_ID,
        Constants.ID_STORE_KEYS.USER_DETAILS,
        Constants.ID_STORE_KEYS.AB_TEST,
        Constants.ID_STORE_KEYS.AB_TEST_1,
        Constants.ID_STORE_KEYS.AB_TEST_2,
        Constants.ID_STORE_KEYS.AB_TEST_3,
        Constants.ID_STORE_KEYS.AB_TEST_4,
        Constants.ID_STORE_KEYS.AB_TEST_5,
        Constants.ID_STORE_KEYS.AB_TEST_6,
        Constants.ID_STORE_KEYS.AB_TEST_7,
        Constants.ID_STORE_KEYS.AB_TEST_8,
        Constants.ID_STORE_KEYS.AB_TEST_9,
        Constants.ID_STORE_KEYS.AB_TEST_10,
        Constants.ID_STORE_KEYS.AB_TEST_11,
        Constants.ID_STORE_KEYS.AB_TEST_12,
        Constants.ID_STORE_KEYS.AB_TEST_13,
        Constants.ID_STORE_KEYS.AB_TEST_14,
        Constants.ID_STORE_KEYS.AB_TEST_15,
        Constants.ID_STORE_KEYS.AB_TEST_16,
        Constants.ID_STORE_KEYS.AB_TEST_17,
        Constants.ID_STORE_KEYS.AB_TEST_18,
        Constants.ID_STORE_KEYS.AB_TEST_19,
        Constants.ID_STORE_KEYS.AB_TEST_20,
        Constants.ID_STORE_KEYS.AB_TEST_21,
        Constants.ID_STORE_KEYS.AB_TEST_22,
        Constants.ID_STORE_KEYS.AB_TEST_23,
        Constants.ID_STORE_KEYS.AB_TEST_24,
        Constants.ID_STORE_KEYS.AB_TEST_25,
        Constants.ID_STORE_KEYS.AB_TEST_26,
        Constants.ID_STORE_KEYS.AB_TEST_27,
        Constants.ID_STORE_KEYS.AB_TEST_28,
        Constants.ID_STORE_KEYS.AB_TEST_29,
        Constants.ID_STORE_KEYS.AB_TEST_30,
        Constants.ID_STORE_KEYS.AB_TEST_31,
        Constants.ID_STORE_KEYS.AB_TEST_32,
        Constants.ID_STORE_KEYS.AB_TEST_33,
        Constants.ID_STORE_KEYS.AB_TEST_34,
        Constants.ID_STORE_KEYS.AB_TEST_35,
        Constants.ID_STORE_KEYS.AB_TEST_36,
        Constants.ID_STORE_KEYS.AB_TEST_37,
        Constants.ID_STORE_KEYS.AB_TEST_38,
        Constants.ID_STORE_KEYS.AB_TEST_39,
        Constants.ID_STORE_KEYS.AB_TEST_40,
        Constants.ID_STORE_KEYS.AB_TEST_41,
        Constants.ID_STORE_KEYS.AB_TEST_42,
        Constants.ID_STORE_KEYS.AB_TEST_43,
        Constants.ID_STORE_KEYS.AB_TEST_44,
        Constants.ID_STORE_KEYS.AB_TEST_45,
        Constants.ID_STORE_KEYS.AB_TEST_46,
        Constants.ID_STORE_KEYS.AB_TEST_47,
        Constants.ID_STORE_KEYS.AB_TEST_48,
        Constants.ID_STORE_KEYS.AB_TEST_49,
        Constants.ID_STORE_KEYS.AB_TEST_50,
        Constants.USER.EMPLOYER_TYPE_VAL,
        Constants.USER_DETAILS_KEY.EMAIL,
        Constants.USER_DETAILS_KEY.DOB,
        Constants.USER_DETAILS_KEY.GENDER,
        Constants.USER_DETAILS_KEY.ZIP_CODE,
        Constants.URL_ARGS.ADGROUP_ID,
        Constants.URL_ARGS.PLACEMENT_ID,
        Constants.URL_ARGS.CREATIVE_ID,
        Constants.URL_ARGS.TARGET_ID,
        Constants.URL_ARGS.CLID,
        Constants.URL_ARGS.PUBLISHER_SUBID,
        Constants.ID_STORE_KEYS.PUBLISHER_SUB_SOURCE,
        Constants.ID_STORE_KEYS.DEVICE_WIDTH,
        Constants.ID_STORE_KEYS.DEVICE_HEIGHT,
        Constants.ID_STORE_KEYS.DEVICE_PIXEL_RATIO,
        Constants.ID_STORE_KEYS.PAGE_URL,
        Constants.ID_STORE_KEYS.JOB_TYPE_VAL,
        Constants.ID_STORE_KEYS.EVERFLOW_TRANSACTION_ID,
        Constants.ID_STORE_KEYS.LANDING_JT,
        Constants.ID_STORE_KEYS.LANDING_KEYWORD,
        Constants.ID_STORE_KEYS.DOMAIN,
        Constants.ID_STORE_KEYS.P_DOMAIN,
        Constants.ID_STORE_KEYS.IS_RDR,
        Constants.ID_STORE_KEYS.VISIT_COUNT,
        Constants.ID_STORE_KEYS.REGISTERED_VISIT_COUNT,
        Constants.ID_STORE_KEYS.PHONE_VERIFICATION,
        Constants.ID_STORE_KEYS.EMAIL_OPT_IN_TS,
        Constants.ID_STORE_KEYS.PHONE_OPT_IN_TS,
        Constants.ID_STORE_KEYS.USER_STATUS,
        Constants.ID_STORE_KEYS.USER_STATUS_CURRENT,
        Constants.ID_STORE_KEYS.USER_STATUS_IS_SUBSCRIBED,
        Constants.ID_STORE_KEYS.USER_STATUS_IS_REGISTERED,
        Constants.ID_STORE_KEYS.MULTI_BRAND_LOGO,
        Constants.ID_STORE_KEYS.EXT1,
        Constants.ID_STORE_KEYS.EXT2,
        Constants.ID_STORE_KEYS.EXT3,
        Constants.ID_STORE_KEYS.EXT4,
        Constants.ID_STORE_KEYS.EXT5,
        Constants.ID_STORE_KEYS.HOURLY_PAY,
    ],
    // These values are send with certain push event calls. For eg, spot no. is sent with all ads psuh events and then these values are removed
    ALLOWED_TRANSIENT_VALS: [
        Constants.ID_STORE_KEYS.QUESTION_NUMBER,
        Constants.ID_STORE_KEYS.QUESTION_NAME,
        // Constants.ID_STORE_KEYS.AD_SPOT_NUMBER
    ],

    /**
     * Event to category map. Any event that is not here will have a default category (like miscellaneous).
     */
    EVENT_TO_CATEGORY_MAP: {
        [Constants.EVENT.SURVEY_QUESTION_ANSWERED]: Constants.ANALYTICS.CATEGORY.SURVEY,
        [Constants.EVENT.LANDING_PAGE_IMP]: Constants.ANALYTICS.CATEGORY.USER,
        [Constants.EVENT.USER_REGISTRATION_COMPLETE]: Constants.ANALYTICS.CATEGORY.USER,
        [Constants.EVENT.SURVEY_PATH_IMP]: Constants.ANALYTICS.CATEGORY.SURVEY,
        [Constants.EVENT.SURVEY_PAGE_IMP]: Constants.ANALYTICS.CATEGORY.SURVEY,
        [Constants.EVENT.SURVEY_COMPLETE]: Constants.ANALYTICS.CATEGORY.SURVEY,
        [Constants.EVENT.ADS_IMP]: Constants.ANALYTICS.CATEGORY.ADS,
        [Constants.EVENT.AD_CLICK]: Constants.ANALYTICS.CATEGORY.ADS,
        [Constants.EVENT.GRATIFICATION_PAGE_IMP]: Constants.ANALYTICS.CATEGORY.GRATIFICATION,
        [Constants.EVENT.GRATIFICATION_AVAIL]: Constants.ANALYTICS.CATEGORY.GRATIFICATION,
    },
};

export { EVENT_CONSTANTS };
