import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import { CommonHelper } from 'helpers';

// eslint-disable-next-line max-lines-per-function
export const interestMediaPreping = async email => {
    const sanitizedEmail = email && typeof email === 'string' ? email.trim().toLowerCase() : '';

    const freeSamplesPingBody = JSON.stringify({
        cid: 'b51dr',
        // eslint-disable-next-line camelcase
        user_email_address: sanitizedEmail,
    });

    try {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'im_fsp');
        const intrestMediaFreeSamplesPing = await fetch(Constants.API_PROXY_URL, {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                url: Constants.PREPING_URLS.INTEREST_MEDIA_FREE_SAMPLES,
                options: {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: freeSamplesPingBody,
                },
            }),
        }).then(res => res.json());

        if (intrestMediaFreeSamplesPing && intrestMediaFreeSamplesPing.success === true) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.IST_MEDIA_SAMPLES_PREPING, 'success');
            CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SUCCESS, 'im_fsp');
        } else {
            CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'im_fsp', {
                error: `preping failed`,
            });
        }
    } catch (e) {
        console.log(e);
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'im_fsp', {
            error: `error while sending preping: ${e.message}`,
        });
    }

    const americanSweepsBody = JSON.stringify({
        cid: 'wawoj',
        // eslint-disable-next-line camelcase
        user_email_address: sanitizedEmail,
    });

    try {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'im_tas');
        const americanSweepsPing = await fetch(Constants.API_PROXY_URL, {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                url: Constants.PREPING_URLS.INTEREST_MEDIA_AMERICAN_SWEEPS,
                options: {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: americanSweepsBody,
                },
            }),
        }).then(res => res.json());

        if (americanSweepsPing && americanSweepsPing.success === true) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.IST_MEDIA_AMERICAN_PREPING, 'success');
            CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SUCCESS, 'im_tas');
        } else {
            CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'im_tas', {
                error: `preping failed`,
            });
        }
    } catch (e) {
        console.log(e);
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'im_tas', {
            error: `error while sending preping: ${e.message}`,
        });
    }

    const topSurveySpotBody = JSON.stringify({
        cid: 'etm1r',
        // eslint-disable-next-line camelcase
        user_email_address: sanitizedEmail,
    });

    try {
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SEND, 'im_ttss');
        const topSurveyPing = await fetch(Constants.API_PROXY_URL, {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                url: Constants.PREPING_URLS.INTEREST_MEDIA_TOP_SURVEY,
                options: {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: topSurveySpotBody,
                },
            }),
        }).then(res => res.json());

        if (topSurveyPing && topSurveyPing.success === true) {
            IdStore.storeIdForKey(Constants.ID_STORE_KEYS.IST_MEDIA_SURVEY_PREPING, 'success');
            CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_SUCCESS, 'im_ttss');
        } else {
            CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'im_ttss', {
                error: `preping failed`,
            });
        }
    } catch (e) {
        console.log(e);
        CommonHelper.triggerPreppingEvent(Constants.EVENT.PREPING_FAILURE, 'im_ttss', {
            error: `error while sending preping: ${e.message}`,
        });
    }
};
