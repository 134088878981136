import { CommonHelper } from 'helpers';
import { IdStore } from 'dataStore/index';
import { ConfigProvider } from '../index';
import Constants from 'common/constants';

export default class GiftCards {
    constructor(data) {
        this.initialize(data);
    }

    /**
     * Initialize Features in the application, leverage on caching
     */
    async initialize(data) {
        if (CommonHelper.isNull(data)) {
            await ConfigProvider.initialize();
            data = ConfigProvider.data;
        }

        return data.giftCardConfig === null
            ? false
            : this.setGiftCardsIntoStorage(data.giftCardConfig);
    }

    setGiftCardsIntoStorage(data) {
        if (CommonHelper.isNull(data)) return false;
        this.giftCards = data;
        IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GIFT_CARDS, JSON.stringify(data));

        return true;
    }
}
