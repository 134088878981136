import { ConfigProvider } from 'providers';
import Utils from 'common/utils';
import Constants from 'common/constants';
import { GiftCardUrlService, fetchSessionData } from 'datasource';
import { userDataStore, IdStore } from 'dataStore';
import AppCommon from '../../apps/appCommon';

export const getGiftCards = () => {
    return ConfigProvider.GiftCards.giftCards;
};

export const getSelectedGiftCardDetails = (giftCardId, giftCards) => {
    if (giftCards && giftCards.length > 0) {
        const selectedGiftCard = giftCards.filter(giftCard => giftCard.id === giftCardId);

        return selectedGiftCard && selectedGiftCard.length > 0 && selectedGiftCard[0];
    }

    return {};
};

export const isDirectEntryValid = async () => {
    const surlToken = Utils.getValForKeyFromCurrentUrl(Constants.URL_ARGS.SURL_TOKEN);

    if (surlToken) {
        const res = await GiftCardUrlService.getValidationForSurl(surlToken);

        if (res && res.data && res.status && res.status === 'valid') {
            if (Utils.isEmptyStr(IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.GEO_ZIP_CODE))) {
                const sessionData = await fetchSessionData();
                AppCommon.storeSessionResp(sessionData);
            }
            const giftCardsAvailable = getGiftCards();
            const selectedGiftCard = res.data && res.data.giftCardID ? res.data.giftCardID : '';
            const giftCardDetails = getSelectedGiftCardDetails(
                selectedGiftCard,
                giftCardsAvailable,
            );

            if (giftCardDetails && Object.keys(giftCardDetails).length > 0) {
                IdStore.storeIdForKey('directEntry', 'success');
                IdStore.storeIdForKey(
                    Constants.ID_STORE_KEYS.GIFT_CARD_DETAILS,
                    JSON.stringify(giftCardDetails),
                );
                const emailUser = res.data.emailID ? res.data.emailID : '';
                const formData = {
                    email: emailUser,
                };
                userDataStore.storeUserDataInCache(formData);
                IdStore.storeIdForKey(Constants.ID_STORE_KEYS.GIFTCARD_ID, selectedGiftCard);
                ConfigProvider.fetchScopeConfig([1]);
            }
        } else {
            if (!Utils.isEmptyStr(Utils.getCookie('survey'))) {
                Utils.deleteCookie('survey');
            }

            if (!Utils.isEmptyStr(Utils.getCookie('offers'))) {
                Utils.deleteCookie('offers');
            }

            if (!Utils.isEmptyStr(Utils.getCookie('deals'))) {
                Utils.deleteCookie('deals');
            }
        }
    }
};
