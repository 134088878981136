import Utils from 'common/utils';
import { IdStore } from 'dataStore';
import Constants from 'common/constants';
import EventManager from 'eventManager/eventManager';
import qs from 'qs';
import { getAdUnit } from '../adUnitHelper';

/**
 *
 * @param {Object} prepingData data to be posted in request
 * @param {Object} eventData data for triggered events
 * @param {Object} storeKeyVal key value pair to be stored in session on success
 * @param {string} storeKeyVal.key
 * @param {string} storeKeyVal.value
 * @param {string} storeKeyVal.token
 * @returns {undefined}
 */
const triggerPreping = async (prepingData, eventData, storeKeyVal) => {
    if (Utils.isEmptyObj(prepingData)) {
        console.error('Preping data not found');

        return;
    }

    let oldPrepingExists;

    if (!Utils.isEmptyObj(storeKeyVal)) {
        oldPrepingExists = IdStore.fetchIdForKey(storeKeyVal.key);
    }

    if (!Utils.isNull(oldPrepingExists)) {
        IdStore.removeIdForKey(storeKeyVal);
    }

    const { postalcode, dob, email, phone } = prepingData;

    if (postalcode && dob && email && phone) {
        eventData.zip = postalcode;
        eventData.dob = dob;
        eventData.email = email;
        // eslint-disable-next-line camelcase
        eventData.phone_nuber = phone;
    }

    try {
        EventManager.sendEvent(Constants.EVENT.PREPING_SEND, eventData, Constants.EVENT_TYPE.DATA);

        const res = await fetch(Constants.API_PROXY_URL, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                url: Constants.PREPING_URLS.CONVERZATE,
                options: {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'X-API-Key': Constants.PREPING_API_KEY.CONVERZATE,
                    },
                    body: qs.stringify(prepingData),
                },
            }),
        }).then(res => res.json());

        if (res && res.code === 200 && res.message === 'accept') {
            if (!Utils.isEmptyObj(storeKeyVal)) {
                IdStore.storeIdForKey(storeKeyVal.key, storeKeyVal.value);
                IdStore.storeIdForKey(storeKeyVal.token, res.token);
            }

            EventManager.sendEvent(
                Constants.EVENT.PREPING_SUCCESS,
                eventData,
                Constants.EVENT_TYPE.DATA,
            );
        } else {
            EventManager.sendEvent(
                Constants.EVENT.PREPING_FAILURE,
                {
                    ...eventData,
                    error: `unexpected response`,
                },
                Constants.EVENT_TYPE.DATA,
            );
        }
    } catch (e) {
        EventManager.sendEvent(
            Constants.EVENT.PREPING_FAILURE,
            {
                ...eventData,
                error: `error while sending preping: ${e.message}`,
            },
            Constants.EVENT_TYPE.DATA,
        );
        console.log(e);
    }
};

/**
 *
 * @param {Object} userParams user details for Ad unit
 * @param {Object} prepingData data to be posted in request
 * @returns {undefined}
 */
// eslint-disable-next-line max-lines-per-function
const converzatePreping = async (userParams, prepingData) => {
    const geneticCardioAU = getAdUnit(
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.GENETIC_CARDIO_SWEEPS.STAG
            : Constants.AD_UNIT_ID.GENETIC_CARDIO_SWEEPS.PROD,
        {
            userParams,
        },
        'Converzate Genetic Cardio',
        {
            key: Constants.ID_STORE_KEYS.GENETIC_CARDIO_SWEEPS,
            value: 'unbranded',
        },
        eventData => {
            triggerPreping(
                {
                    ...prepingData,
                    projectId: Constants.AD_UNIT_ID.GENETIC_CARDIO_SWEEPS.PROJECT_ID,
                },
                eventData,
                {
                    key: Constants.ID_STORE_KEYS.GENETIC_CARDIO_SWEEPS_PREPING,
                    value: 'success',
                    token: Constants.ID_STORE_KEYS.GENETIC_CARDIO_SWEEPS_TOKEN,
                },
            );
        },
    );

    const pgxAU = getAdUnit(
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.PGX_SWEEPS.STAG
            : Constants.AD_UNIT_ID.PGX_SWEEPS.PROD,
        {
            userParams,
        },
        'Converzate PGX',
        {
            key: Constants.ID_STORE_KEYS.PGX_SWEEPS,
            value: 'unbranded',
        },
        eventData => {
            triggerPreping(
                {
                    ...prepingData,
                    projectId: Constants.AD_UNIT_ID.PGX_SWEEPS.PROJECT_ID,
                },
                eventData,
                {
                    key: Constants.ID_STORE_KEYS.PGX_SWEEPS_PREPING,
                    value: 'success',
                    token: Constants.ID_STORE_KEYS.PGX_SWEEPS_TOKEN,
                },
            );
        },
    );

    const zantacAU = getAdUnit(
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.ZANTAC_SWEEPS.STAG
            : Constants.AD_UNIT_ID.ZANTAC_SWEEPS.PROD,
        {
            userParams,
        },
        'Zantac Sweeps',
        {
            key: Constants.ID_STORE_KEYS.ZANTAC_SWEEPS,
            value: 'unbranded',
        },
        eventData => {
            triggerPreping(
                {
                    ...prepingData,
                    projectId: Constants.AD_UNIT_ID.ZANTAC_SWEEPS.PROJECT_ID,
                },
                eventData,
                {
                    key: Constants.ID_STORE_KEYS.ZANTAC_SWEEPS_PREPING,
                    value: 'success',
                    token: Constants.ID_STORE_KEYS.ZANTAC_SWEEPS_TOKEN,
                },
            );
        },
    );

    const roundUpAU = getAdUnit(
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.ROUNDUP_SWEEPS.STAG
            : Constants.AD_UNIT_ID.ROUNDUP_SWEEPS.PROD,
        {
            userParams,
        },
        'Roundup',
        {
            key: Constants.ID_STORE_KEYS.ROUNDUP_SWEEPS,
            value: 'unbranded',
        },
        eventData => {
            triggerPreping(
                {
                    ...prepingData,
                    projectId: Constants.AD_UNIT_ID.ROUNDUP_SWEEPS.PROJECT_ID,
                },
                eventData,
                {
                    key: Constants.ID_STORE_KEYS.ROUNDUP_SWEEPS_PREPING,
                    value: 'success',
                    token: Constants.ID_STORE_KEYS.ROUNDUP_SWEEPS_TOKEN,
                },
            );
        },
    );

    const neurologicGeneticAU = getAdUnit(
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.NEUROLOGICAL_GENETIC.STAG
            : Constants.AD_UNIT_ID.NEUROLOGICAL_GENETIC.PROD,
        {
            userParams,
        },
        'Converzate-NeurologicalGenetic',
        {
            key: Constants.ID_STORE_KEYS.NEUROLOGICAL_GENETIC,
            value: 'unbranded',
        },
        eventData => {
            triggerPreping(
                {
                    ...prepingData,
                    projectId: Constants.AD_UNIT_ID.NEUROLOGICAL_GENETIC.PROJECT_ID,
                },
                eventData,
                {
                    key: Constants.ID_STORE_KEYS.NEUROLOGICAL_GENETIC_PREPING,
                    value: 'success',
                    token: Constants.ID_STORE_KEYS.NEUROLOGICAL_GENETIC_TOKEN,
                },
            );
        },
    );

    const herniaMeshAU = getAdUnit(
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.CONVERZATE_HERNIA_MESH.STAG
            : Constants.AD_UNIT_ID.CONVERZATE_HERNIA_MESH.PROD,
        {
            userParams,
        },
        'Converzate Hernia Mesh Tort',
        {
            key: Constants.ID_STORE_KEYS.CONVERZATE_HERNIA_MESH,
            value: 'unbranded',
        },
        eventData => {
            triggerPreping(
                {
                    ...prepingData,
                    projectId: Constants.AD_UNIT_ID.CONVERZATE_HERNIA_MESH.PROJECT_ID,
                },
                eventData,
                {
                    key: Constants.ID_STORE_KEYS.CONVERZATE_HERNIA_MESH_PREPING,
                    value: 'success',
                    token: Constants.ID_STORE_KEYS.CONVERZATE_HERNIA_MESH_TOKEN,
                },
            );
        },
    );

    const earplug3m = getAdUnit(
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.CONVERZATE_3M_EARPLUG.STAG
            : Constants.AD_UNIT_ID.CONVERZATE_3M_EARPLUG.PROD,
        {
            userParams,
        },
        'Converzate 3M Earplug',
        {
            key: Constants.ID_STORE_KEYS.CONVERZATE_3M_EARPLUG,
            value: 'unbranded',
        },
        eventData => {
            triggerPreping(
                {
                    ...prepingData,
                    projectId: Constants.AD_UNIT_ID.CONVERZATE_3M_EARPLUG.PROJECT_ID,
                },
                eventData,
                {
                    key: Constants.ID_STORE_KEYS.CONVERZATE_3M_EARPLUG_PREPING,
                    value: 'success',
                    token: Constants.ID_STORE_KEYS.CONVERZATE_3M_EARPLUG_TOKEN,
                },
            );
        },
    );

    const toxicBabyAU = getAdUnit(
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.CONVERZATE_TOXIC_BABY.STAG
            : Constants.AD_UNIT_ID.CONVERZATE_TOXIC_BABY.PROD,
        {
            userParams,
        },
        'Converzate Toxic baby Formula',
        {
            key: Constants.ID_STORE_KEYS.CONVERZATE_TOXIC_BABY,
            value: 'unbranded',
        },
        eventData => {
            triggerPreping(
                {
                    ...prepingData,
                    projectId: Constants.AD_UNIT_ID.CONVERZATE_TOXIC_BABY.PROJECT_ID,
                },
                eventData,
                {
                    key: Constants.ID_STORE_KEYS.CONVERZATE_TOXIC_BABY_PREPING,
                    value: 'success',
                    token: Constants.ID_STORE_KEYS.CONVERZATE_TOXIC_BABY_TOKEN,
                },
            );
        },
    );

    const philipCancerAU = getAdUnit(
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.CONVERZATE_PHILIP_CANCER.STAG
            : Constants.AD_UNIT_ID.CONVERZATE_PHILIP_CANCER.PROD,
        {
            userParams,
        },
        'Converzate Philips CPAP Cancer',
        {
            key: Constants.ID_STORE_KEYS.CONVERZATE_PHILIP_CANCER,
            value: 'unbranded',
        },
        eventData => {
            triggerPreping(
                {
                    ...prepingData,
                    projectId: Constants.AD_UNIT_ID.CONVERZATE_PHILIP_CANCER.PROJECT_ID,
                },
                eventData,
                {
                    key: Constants.ID_STORE_KEYS.CONVERZATE_PHILIP_CANCER_PREPING,
                    value: 'success',
                    token: Constants.ID_STORE_KEYS.CONVERZATE_PHILIP_CANCER_TOKEN,
                },
            );
        },
    );

    const camplejueneAU = getAdUnit(
        Utils.isTestEnv()
            ? Constants.AD_UNIT_ID.CONVERZATE_CAMP_LEJUENE.STAG
            : Constants.AD_UNIT_ID.CONVERZATE_CAMP_LEJUENE.PROD,
        {
            userParams,
        },
        'Converzate Camp Lejeune',
        {
            key: Constants.ID_STORE_KEYS.CONVERZATE_CAMP_LEJUENE,
            value: 'unbranded',
        },
        eventData => {
            triggerPreping(
                {
                    ...prepingData,
                    projectId: Constants.AD_UNIT_ID.CONVERZATE_CAMP_LEJUENE.PROJECT_ID,
                    subid: `${IdStore.fetchIdForKey(
                        Constants.ID_STORE_KEYS.UTM_SOURCE,
                    )}-${IdStore.fetchIdForKey(Constants.ID_STORE_KEYS.UTM_CAMPAIGN)}`,
                    source: 'connexus',
                },
                eventData,
                {
                    key: Constants.ID_STORE_KEYS.CONVERZATE_CAMP_LEJUENE_PREPING,
                    value: 'success',
                    token: Constants.ID_STORE_KEYS.CONVERZATE_CAMP_LEJUENE_TOKEN,
                },
            );
        },
    );

    Promise.allSettled([
        geneticCardioAU,
        pgxAU,
        zantacAU,
        roundUpAU,
        neurologicGeneticAU,
        herniaMeshAU,
        earplug3m,
        toxicBabyAU,
        philipCancerAU,
        camplejueneAU,
    ]).then(() => {
        return true;
    });
};

export { converzatePreping };
