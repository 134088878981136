import React from 'react';
import RouterPrefixHandler from 'common/routePrefixHandler';
import flavors, { getFlavor } from 'flavors.macro';
import { useStyles } from './header.styles';
import { CommonHelper, AbTestHelper } from 'helpers';
import Utils from 'common/utils';
import Constants from 'common/constants';
import cn from 'classnames';

flavors();

/**
 * App header
 * @param {*} props
 * @returns Header
 */
export const Header = props => {
    const classes = useStyles(props);
    const flavor = getFlavor('layout-theme');
    const pathName = window.location.pathname;

    return (
        <header
            className={cn(
                `${classes.container} ${
                    !Utils.checkMobileDevice() &&
                    !CommonHelper.isLander() &&
                    !AbTestHelper.HALLOWEEN_THEME() &&
                    classes.bgWhite
                } ${
                    !CommonHelper.isLander() &&
                    AbTestHelper.HALLOWEEN_THEME() &&
                    classes.halloweenHeader
                } ${
                    window.location.pathname === Constants.PAGE_ENDPOINTS.ADS &&
                    AbTestHelper.midPathOffersBackgroundTest() &&
                    classes.bgWhite
                } ${
                    window.location.pathname === Constants.PAGE_ENDPOINTS.ADS &&
                    AbTestHelper.midPathOffersBackgroundTestVariant() &&
                    classes.bgBlack
                }`,
                {
                    [classes.bgBlack]:
                        AbTestHelper.RDSConfirmationPageV3() &&
                        (pathName === Constants.PAGE_ENDPOINTS.ENTRYCONFIRMATION ||
                            pathName === Constants.PAGE_ENDPOINTS.ENTRY_EMAIL_CONFIRMATION),
                },
            )}>
            <picture
                className={cn(
                    `${classes.picture} ${
                        window.location.pathname ===
                            Constants.PAGE_ENDPOINTS.OFFERS_NOT_AVAILABLE && classes.pictureBgWhite
                    }`,
                )}>
                <source
                    srcSet={RouterPrefixHandler.getImagePath(`images/${flavor}/logos/site.webp`)}
                    type="image/webp"
                />
                <img
                    alt="Logo"
                    height="42"
                    src={RouterPrefixHandler.getImagePath(`images/${flavor}/logos/site.png`)}
                    width="150"
                />
            </picture>
        </header>
    );
};
