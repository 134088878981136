/* eslint-disable max-lines-per-function */
import React from 'react';
import { AnimatedSwitch } from 'react-router-transition';
import { Route, Redirect } from 'react-router-dom';
import Constants from 'common/constants';
import RoutePrefixHandler from 'common/routePrefixHandler';
// import flavors, { getFlavor } from 'flavors.macro';
import { PageLoader } from 'flavors/sweepsPrizes/components/common/loader/page';
import Utils from 'common/utils';
import { AbTestHelper } from 'helpers';
import { getFlavor } from 'flavors.macro';

// flavors();

// const flavor = getFlavor('layout-theme');

const LANDING = React.lazy(() => import('./apps/landingApp'));
const SURVEY = React.lazy(() => import('./apps/surveyApp'));
const DETAILS_CONFIRMATION = React.lazy(() => import('./apps/detailsConfirmation'));
const ADS_APP = React.lazy(() => import('./apps/adsApp'));
const SHOW_ME_DEALS = React.lazy(() => import('./apps/showMeDealsApp'));
const OFFER_PAGE = React.lazy(() => import('./apps/offerPageApp'));
const ENTRY_CONFIRMATION = React.lazy(() => import('./apps/entryConfirmationApp'));
const ENTRY_EMAIL_CONFIRMATION = React.lazy(() => import('./apps/entryEmailConfirmationApp'));
const ERROR_PAGE = React.lazy(() => import(`flavors/sweepsPrizes/components/pages/error`));
const ALTERNATE_ENTRY = React.lazy(() => import('./apps/alternateEntryApp'));

// Legal Pages
const OFFICIAL_RULES = React.lazy(() => import(`./apps/officialRules`));
const PRIVACY_POLICY = React.lazy(() => import('./apps/privacyPolicy'));
const TERMS_CONDITION = React.lazy(() => import('./apps/termsCondition'));
const CCPA = React.lazy(() => import('./apps/ccpa'));
const CONTACT_US = React.lazy(() => import('./apps/contactUs'));
const UN_SUBSCRIBE = React.lazy(() => import('./apps/unsubscribe'));
const STAND_ALONE_OFFER_WALL = React.lazy(() => import('./apps/standAloneOfferWallApp'));
const OFFERS_NOT_AVAILABLE = React.lazy(() => import('./apps/offerNotAvailable'));
const WINNERS_LIST = React.lazy(() => import('./apps/winnersList'));
const flavor = getFlavor('layout-theme');

export default class Routes extends React.Component {
    render() {
        return (
            <AnimatedSwitch
                atActive={{ opacity: 1 }}
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 0 }}
                className="switch-wrapper">
                <React.Suspense
                    fallback={
                        <PageLoader
                            commonWrapperProps={{
                                fullScreenOverrides:
                                    AbTestHelper.RDSConfirmationPageV3() ||
                                    AbTestHelper.RDSConfirmationPageV2(),
                            }}
                        />
                    }>
                    {flavor !== 'sweepsPrizes' && (
                        <>
                            <Route
                                component={LANDING}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.LANDING,
                                )}
                            />
                            <Route
                                component={SURVEY}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.SURVEY,
                                )}
                            />
                            <Route
                                component={DETAILS_CONFIRMATION}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.DETAILS_CONFIRMATION,
                                )}
                            />
                            <Route
                                component={ADS_APP}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.ADS,
                                )}
                            />
                            <Route
                                component={SHOW_ME_DEALS}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.SHOW_ME_DEAL,
                                )}
                            />
                            <Route
                                component={STAND_ALONE_OFFER_WALL}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.STAND_ALONE_PAGE,
                                )}
                            />
                            <Route
                                component={ENTRY_CONFIRMATION}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.ENTRY_CONFIRMATION,
                                )}
                            />
                            <Route
                                component={ENTRY_EMAIL_CONFIRMATION}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.ENTRY_EMAIL_CONFIRMATION,
                                )}
                            />
                            <Route
                                component={OFFER_PAGE}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.OFFER_WALL,
                                )}
                            />

                            <Route
                                component={ALTERNATE_ENTRY}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.ALTERNATE_ENTRY,
                                )}
                            />
                            <Route
                                component={WINNERS_LIST}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.WINNERS_LIST,
                                )}
                            />
                        </>
                    )}

                    <Route
                        component={PRIVACY_POLICY}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.PRIVACY_POLICY,
                        )}
                    />

                    <Route
                        component={TERMS_CONDITION}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.TERMSANDCONDITIONS,
                        )}
                    />

                    <Route
                        component={CCPA}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.CCPA,
                        )}
                    />

                    <Route
                        component={CONTACT_US}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.CONTACTUS,
                        )}
                    />

                    <Route
                        component={OFFICIAL_RULES}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.OFFICIAL_RULES,
                        )}
                    />

                    <Route
                        component={UN_SUBSCRIBE}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.UNSUBSCRIBE,
                        )}
                    />

                    <Route
                        component={OFFERS_NOT_AVAILABLE}
                        exact
                        path={RoutePrefixHandler.getRouteTemplateForPath(
                            Constants.PAGE_ENDPOINTS.OFFERS_NOT_AVAILABLE,
                        )}
                    />

                    {/* This is just for the dev environment to not raise error for 404*/}
                    <Route component={ERROR_PAGE} exact path="/404" />
                    {/* Defaults to landing if nothing is mentioned */}
                    {flavor !== 'sweepsPrizes' && (
                        <Route
                            component={({ location }) => {
                                return (
                                    <Redirect
                                        to={{
                                            pathname: RoutePrefixHandler.getDefaultRedirectPathForLocation(
                                                location,
                                            ),
                                            search:
                                                location.search ||
                                                (Utils.isTestEnv()
                                                    ? Constants.PAGE_ENDPOINTS
                                                          .DEFAULT_SEARCH_PARAM_STAG
                                                    : Constants.PAGE_ENDPOINTS
                                                          .DEFAULT_SEARCH_PARAM),
                                            hash: 0,
                                        }}
                                    />
                                );
                            }}
                            exact
                            path="/"
                        />
                    )}

                    {flavor === 'sweepsPrizes' && (
                        <>
                            <Route
                                component={STAND_ALONE_OFFER_WALL}
                                exact
                                path={RoutePrefixHandler.getRouteTemplateForPath(
                                    Constants.PAGE_ENDPOINTS.STAND_ALONE_PAGE,
                                )}
                            />
                            {/* This is just for the dev environment to not raise error for 404*/}
                            <Route component={ERROR_PAGE} exact path="/404" />
                            {/* Defaults to landing if nothing is mentioned */}
                            <Route
                                component={({ location }) => {
                                    return (
                                        <Redirect
                                            to={{
                                                pathname: RoutePrefixHandler.getDefaultRedirectPathForLocation(
                                                    location,
                                                ),
                                                search:
                                                    location.search ||
                                                    (Utils.isTestEnv()
                                                        ? Constants.PAGE_ENDPOINTS
                                                              .DEFAULT_SEARCH_PARAM_STAG
                                                        : Constants.PAGE_ENDPOINTS
                                                              .DEFAULT_SEARCH_PARAM),
                                                hash: 0,
                                            }}
                                        />
                                    );
                                }}
                                exact
                                path="/"
                            />
                        </>
                    )}
                </React.Suspense>
            </AnimatedSwitch>
        );
    }
}
