import { Links } from '../../../constants/links';

export const FooterLinks = [
    {
        link: Links.CALIFORNIA_PRIVACY.link,
        text: Links.CALIFORNIA_PRIVACY.text,
    },
    {
        link: Links.TERMS_OF_USE.link,
        text: Links.TERMS_OF_USE.text,
    },
    {
        link: Links.PRIVACY_POLICY.link,
        text: Links.PRIVACY_POLICY.text,
    },
    {
        link: Links.OFFICIAL_RULES.link,
        text: Links.OFFICIAL_RULES.text,
    },
    {
        link: Links.CONTACT_US.link,
        text: Links.CONTACT_US.text,
    },
    {
        link: Links.UNSUBSCRIBE.link,
        text: Links.UNSUBSCRIBE.text,
    },
];
