import WebStoreManager from './webStoreManager';
import EventManager from '../eventManager/eventManager';
import * as Constants from 'common/constants';

let sessionStore = null;

// Just using sessionStorage is an exception in some environments
try {
    sessionStore = sessionStorage;
} catch (e) {
    sessionStore = null;
    EventManager.sendEvent(
        Constants.EVENT.ERROR,
        null,
        Constants.EVENT_TYPE.SESSION_STORAGE_NOT_ACCESSIBLE,
    );
    console.log('ERR: Error setting the session-store - ', e);
}

const store = new WebStoreManager(sessionStore);
export default store;
