import Utils from 'common/utils';
import Network from 'common/network';
import CommonDataSource from './commonDataSource';
import UrlManager from 'common/urlManager';
import Constants from 'common/constants';
import IdStore from 'dataStore/idStore';

export default class SurveyPathIdDataSource {
    static fetchSurveyPathForId(surveyId, targetingParams) {
        return new Promise((resolve, reject) => {
            if (Utils.isEmptyStr(surveyId)) {
                reject(new Error('survey id cannot be empty!'));

                return;
            }

            const surveyPathUrl = UrlManager.getSurveyPathUrlForId(surveyId);
            const headers = {
                'Content-Type': 'application/json',
            };

            Network.post(surveyPathUrl, headers, targetingParams)
                .then(response => {
                    if (response.headers.get('x-request-id')) {
                        IdStore.storeIdForKey(
                            Constants.ID_STORE_KEYS.SURVEY_REQUEST_ID,
                            response.headers.get('x-request-id'),
                        );
                    }

                    if (false === response.ok) {
                        throw new Error('got invalid response');
                    }

                    return response.json();
                })
                .then(respJson => {
                    return CommonDataSource.processJSONResponse(respJson);
                })
                .then(processedResp => {
                    resolve(processedResp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
}
